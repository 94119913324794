import React, { useState } from 'react';
import { Box, Slider } from '@mui/material';
import { timbalGrey } from 'components/CustomColors';
import { BootstrapInput } from './components/BootstrapNumberInput';
import InputHeader from './components/InputHeader';
import { Typography } from '@mui/material';



const SliderInput = ({ title, description = "test", minimum, maximum, step, initialValue, onChange, name, disabled }) => {
    const [value, setValue] = useState(initialValue ?? null);
    const [error, setError] = useState(false);

    const handleSliderChange = (event) => {
        const value = event.target.value;
        if (value < minimum) {
            setValue(minimum);
        }
        else if (value > maximum) {
            setValue(maximum);
        }
        else {
            setValue(value);
        }
        if (onChange) {
            onChange(event);
        }
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value === '' ? '' : Number(event.target.value);
        setValue(newValue);
        if (newValue < minimum || newValue > maximum) {
            setError(true);
        } else {
            setError(false);
        }
        if (onChange) {
            event.target.value = newValue;
            onChange(event);
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: 'column', gap: 1 }}>
            <InputHeader title={title} description={description} boundaries={`min: ${minimum}, max: ${maximum}`} name={name} />
            <Box sx={{ pl: 1, display: 'flex', gap: 2, alignItems: 'center' }}>
                <Slider
                    value={typeof value === 'number' ? value : null}
                    min={minimum}
                    max={maximum}
                    step={step}
                    name={name}
                    onChange={handleSliderChange}
                    disabled={disabled}
                    aria-labelledby="input-slider"
                    sx={{
                        color: '#5012cb',
                        boxShadow: 'none', // Customize the slider track color
                        '& .MuiSlider-thumb': {
                            border: '1px solid #757575',
                            backgroundColor: 'white',
                            boxShadow: 'none', // Remove the thumb shadow
                            height: 16,
                            width: 16,
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        },
                        '& .MuiSlider-rail': {
                            height: 6,
                            backgroundColor: timbalGrey[700],
                            opacity: 1 // Customize the slider rail color
                        },
                    }}
                />
                <BootstrapInput
                    value={value}
                    onChange={handleInputChange}
                    name={name}
                    disabled={disabled}
                    inputProps={{
                        style: { minWidth: 85 },
                        lang: 'en-US',
                        step: step,
                        min: minimum,
                        max: maximum,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                    }} sx={{
                        '& .MuiInputBase-input': {
                            borderColor: error ? 'red' : timbalGrey[300],
                            '&:focus': {
                                borderColor: error ? 'red' : '#5012cb !important',
                            }, // Change border color if there's an error
                        },

                    }}
                />
            </Box>
            {error && (
                <Typography variant="caption" color="error">
                    {minimum !== -Infinity && value < minimum && `Minimum value is ${minimum}.`}
                    {maximum !== Infinity && value > maximum && `Maximum value is ${maximum}.`}
                </Typography>
            )}
        </Box>
    );
}

export default SliderInput;
