import React from 'react';
import { Box } from '@mui/material';
import { timbalGrey } from 'components/CustomColors';
import { Checkbox } from '@mui/material';
import InputHeader from 'components/inputs/components/InputHeader';


const SelectableGlobalInput = ({ input, handleOnChange }) => {

    const handleOnChangeCheck = (e) => {
        const event = { target: { value: e.target.checked, input: input } };
        handleOnChange(event);
    }

    return (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Checkbox
                disabled={input.disabled}
                onChange={handleOnChangeCheck}
                defaultChecked={input.checked || false}
                sx={{
                    p: 0,
                    color: timbalGrey[700],
                    '&.Mui-checked': {
                        color: '#5012cb',
                    },
                    '&.Mui-disabled': {
                        color: timbalGrey[300],
                    }
                }}
            />
            <InputHeader
                title={input.title}
                description={input.description}
            />
        </Box>
    )
}

export default SelectableGlobalInput;
