import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { statusMap } from 'canva/utils/states';
import { timbalGrey } from 'components/CustomColors';
import InputsTooltip from 'components/inputs/components/InputsTooltip';
import TagElement from 'canva/workflow/components/TagElement';
import { TollOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import StepOutputPreview from './StepOutputsPreview';
import { prettyTimeSeconds } from 'utils/miscelanea';
import { AccessTime } from '@mui/icons-material';

const CustomNodePreview = ({ id, data, selected }) => {

    const [outputs, setOutputs] = useState([]);
    const [borderColor, setBorderColor] = useState(timbalGrey[300]);
    const [showOutputs, setShowOutputs] = useState(true);
    const mainNodeRef = useRef(null);
    const outputsRef = useRef(null);

    const handleShowOutputs = () => {
        setShowOutputs(!showOutputs);
    };

    const handleWheel = useCallback((event) => {
        event.preventDefault();
        if (showOutputs && outputsRef.current) {
            const scrollAmount = event.deltaY;
            outputsRef.current.scrollTop += scrollAmount;
        }
    }, [showOutputs]);

    useEffect(() => {
        const node = mainNodeRef.current;
        if (node) {
            node.addEventListener('wheel', handleWheel, { passive: false });
        }
        return () => {
            if (node) {
                node.removeEventListener('wheel', handleWheel);
            }
        };
    }, [handleWheel]);

    useEffect(() => {
        let retOutputs = [];
        if (data.outputs_schema.type === 'object') {
            const properties = data.outputs_schema.properties;
            properties && Object.entries(properties).forEach(([key, value]) => {
                if (data.outputs_data.value && data.outputs_data.value[key]) {
                    value.value = data.outputs_data.value[key];
                    retOutputs.push({ key, value });
                }
            });
        } else if (data.outputs_data.type === 'error') {
            retOutputs.push({
                key: 'output',
                value: { value: data.outputs_data.error.message, title: data.outputs_data.error.error_type }
            });
        } else {
            if (data.outputs_data.value) {
                retOutputs.push({
                    key: 'output',
                    value: { value: data.outputs_data.value, title: 'Result' }
                });
            }
        }
        setOutputs(retOutputs);

        if (data.status.code && data.status.code !== 'success') {
            setBorderColor(statusMap[data.status.code].color);
        } else {
            setBorderColor(timbalGrey[300]);
        }

    }, [data, id]);

    return (
        <Box
            sx={{
                '&:hover': {
                    cursor: 'pointer',
                }
            }}
        >
            <Box ref={mainNodeRef}>
                {data.auto_loop && (
                    <>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                position: 'absolute',
                                top: -7,
                                left: 3,
                                width: 420,
                                height: 73,
                                border: selected ? '2px solid #5012cb' : `2px solid ${borderColor}`,
                                borderRadius: '6px',
                                zIndex: -1,
                            }}
                        />
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                position: 'absolute',
                                top: -11,
                                left: 8,
                                width: 419,
                                height: 73,
                                border: selected ? '2px solid #5012cb' : `2px solid ${borderColor}`,
                                borderRadius: '6px',
                                zIndex: -2,
                            }}
                        />
                        <InputsTooltip
                            title="AutoLoop is active for this step. It will be executed as many times as the number of items in the input array."
                            placement="top"
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    position: 'absolute',
                                    top: -30,
                                    left: 'auto',
                                    right: -7,
                                    color: timbalGrey[700],
                                    zIndex: 1,
                                }}
                            >
                                AutoLoop
                            </Typography>
                        </InputsTooltip>
                    </>
                )}

                <Paper
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 10px',
                        borderRadius: '4px',
                        width: 400,
                        position: 'relative',
                        //border: selected ? '2px solid #5012cb' : `2px solid ${borderColor}`, // Grey border
                    }}
                    elevation={0}
                >

                    {/* Handle for connections on the left */}
                    <Handle
                        type="target"
                        position={Position.Left}
                        style={{
                            borderRadius: '0px',
                            border: 'none',
                            background: 'transparent',
                            left: '-15px',
                            width: 30,
                            height: 60,
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <Box sx={{
                            width: 6,
                            height: 12,
                            borderRadius: '6px',
                            backgroundColor: grey[800],
                            pointerEvents: 'none',
                        }} />
                    </Handle>
                    <Avatar
                        src={data.icon_url}
                        alt="icon"
                        variant='rounded'
                        sx={{
                            width: 30,
                            height: 30,
                            marginRight: '10px',
                            borderRadius: '6px',

                        }}
                    />
                    <Box sx={{ flexGrow: 1, alignItems: 'center' }}>
                        <Typography noWrap variant="body1" sx={{
                            fontWeight: 'bold',
                            maxWidth: 300,
                            paddingRight: '10px',
                        }}>
                            {data.name ?? id}
                        </Typography>
                    </Box>
                    {/* Status Icon */}
                    {data.status.code && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 30, width: 30 }}>
                        {statusMap[data.status.code].icon}
                    </Box>}

                    {/* Handle for connections on the right */}
                    <Handle
                        type="source"
                        position={Position.Right}
                        style={{
                            borderRadius: '0px',
                            border: 'none',
                            background: 'transparent',
                            right: '-15px',
                            width: 30,
                            height: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                        }}>
                        <Box sx={{
                            width: 6,
                            height: 12,
                            borderRadius: '6px',
                            backgroundColor: grey[800],
                            pointerEvents: 'none',

                        }} />
                    </Handle>
                </Paper >

                <Box
                    sx={{
                        backgroundColor: timbalGrey[300],
                        position: 'absolute',
                        top: -3,
                        zIndex: -1,
                        left: -3,
                        width: 418,
                        height: 69,
                        border: selected ? '2px solid #5012cb' : `2px solid ${borderColor}`,
                        borderRadius: '6px',
                        p: 0.25,
                        display: 'flex',
                        alignItems: 'end'
                    }}
                >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center' }}>
                        {data.tags && data.tags.filter(tag => tag.type === 'task' || tag.type === 'entity').map((tag, index) => (
                            <TagElement key={index} tag={tag} />
                        ))}
                    </Box>

                    <Box flexGrow={1} />

                    {data.time && <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 0.25,
                            px: 0.5,
                            py: 0.25,
                            backgroundColor: timbalGrey[100],
                            borderRadius: '4px',
                            mr: 0.5,
                        }}>
                        <AccessTime sx={{ height: 15, width: 15 }} />
                        <Typography variant="caption" sx={{ fontSize: 12, lineHeight: 1.1 }}>{prettyTimeSeconds(data.time)}</Typography>
                    </Box>}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 0.25,
                            px: 0.5,
                            py: 0.25,
                            backgroundColor: timbalGrey[100],
                            borderRadius: '4px'
                        }}>
                        <TollOutlined sx={{ height: 15, width: 15 }} />
                        <Typography variant="caption" sx={{ fontSize: 12, lineHeight: 1.1 }}>{`${data.credits || 0}`}</Typography>
                    </Box>
                </Box>

                {!showOutputs && outputs.length > 0 && <Box sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end',
                    position: 'absolute',
                    top: 75,
                    right: 0,
                }}>
                    <Typography
                        onClick={handleShowOutputs}
                        variant="caption"
                        sx={{
                            color: timbalGrey[700],
                            cursor: 'pointer',
                            mt: 0.5,
                            '&:hover': {
                                color: '#5012cb',
                                textDecoration: 'underline',
                            }
                        }}
                    >Show output
                    </Typography>
                </Box>}

                {showOutputs && outputs.length > 0 && <Box

                    sx={{
                        mt: 1,
                        p: 2,
                        pt: 1,
                        position: 'absolute',
                        top: 75,
                        left: -3,
                        width: 390,
                        backgroundColor: 'white',
                        borderRadius: '6px',
                        border: selected ? '2px solid #5012cb' : `2px solid ${timbalGrey[300]}`, // Grey border
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', pb: 2 }}>
                        <Typography
                            onClick={handleShowOutputs}
                            variant="caption"
                            sx={{
                                textAlign: 'right',
                                color: timbalGrey[700],
                                cursor: 'pointer',
                                mt: 0.5,
                                '&:hover': {
                                    color: '#5012cb',
                                    textDecoration: 'underline',
                                }
                            }}
                        >Hide output
                        </Typography>
                    </Box>
                    <Box
                        ref={outputsRef}
                        sx={{
                            maxHeight: 600,
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2
                        }}>
                        {outputs.map((output, index) => (
                            <StepOutputPreview key={index} output={output} />
                        ))}
                    </Box>
                </Box>}
            </Box>
        </Box>
    );
};

export default CustomNodePreview;
