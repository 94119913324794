import { useState } from 'react';
import { Dialog, Typography, Box, IconButton } from '@mui/material';
import { BoltOutlined, CancelOutlined } from '@mui/icons-material';
import TextInput from 'components/inputs/TextInput';
import { MainButtonCanvas, ModalCancelButton } from 'components/CustomButtons';
import { timbalGrey } from 'components/CustomColors';
import { useApi } from 'api/ApiContext';
import { useParams } from 'react-router-dom';
const NewDeployModal = ({ openNewDeployDialog, setOpenNewDeployDialog, setOpenDeployDialog }) => {

    const [name, setName] = useState('');
    const [disabled, setDisabled] = useState(false);

    const { postFlowsVersions } = useApi();

    const { id } = useParams();

    const handleCreateDeploy = async () => {
        const resp = await postFlowsVersions(id, name);
        if (resp.success) {
            handleClose();
            setOpenDeployDialog(true);
        }
    }

    const handleClose = () => {
        setOpenNewDeployDialog(false);
        setName('');
    }

    return (
        <Dialog
            open={openNewDeployDialog}
            onClose={handleClose}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleClose}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>New deploy</Typography>
                <TextInput
                    errorCallback={(error) => {
                        setDisabled(error);
                    }}
                    label='Deploy version code'
                    description={'This is the version of the deploy. It will be used to identify the deploy in the future and it should be unique.'}
                    initialValue={name}
                    //^\d+.\d+.\d+(-\w+.\d+)?$
                    pattern={'^\\d+.\\d+.\\d+(-\\w+.\\d+)?$'}
                    tipChild={<></>}
                    placeholder={'Enter version code'}
                    title={'Version code'}
                    onChange={(e) => setName(e.target.value)}
                    multiline={false} />
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Version code should be like [major].[minor].[patch] and optional -[stage].[number]
                    The new version should be higher than the current one.
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, p: 2, border: `1px solid ${timbalGrey[300]}`, borderRadius: '6px', backgroundColor: timbalGrey[100], fontSize: 14 }}>
                    <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>
                        Examples:
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2">Alpha</Typography>
                        <Typography variant="body2">1.2.0-alpha.1</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2">Beta</Typography>
                        <Typography variant="body2">1.2.0-beta.2</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2">Release candidate (RC)</Typography>
                        <Typography variant="body2">1.2.0-rc.3</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2">Release</Typography>
                        <Typography variant="body2">1.2.0</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2">Post-release fixes</Typography>
                        <Typography variant="body2">1.2.5</Typography>

                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <ModalCancelButton onClick={handleClose} >
                            <Typography variant="body2">
                                Cancel
                            </Typography>
                        </ModalCancelButton>
                        <MainButtonCanvas onClick={handleCreateDeploy}
                            startIcon={<BoltOutlined />}
                            disabled={disabled || !name}
                        >
                            <Typography variant="body2" >
                                Create
                            </Typography>
                        </MainButtonCanvas>
                    </Box>
                </Box>
            </Box>
        </Dialog >
    );
}

export default NewDeployModal;