import React, { useState, useRef, useEffect } from 'react';
import { InputBase, Box, Popper, Paper, List, Typography, Avatar, CircularProgress, AvatarGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddOutlined, BoltOutlined, SearchRounded } from '@mui/icons-material';
import { timbalGrey } from 'components/CustomColors';
import { useApi } from 'api/ApiContext';
import { useWorkflow } from '../WorkflowContext';
import LoaderWithText from 'components/LoaderWithText';
import { MainButtonCanvas } from 'components/CustomButtons';
import { millisToDate, prettyInt } from 'utils/miscelanea';
import { useRightDrawer } from '../RightDrawerProvider';
import { useReactFlow } from 'reactflow';

const SearchBarContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(9), // adjust to be just below the AppBar
    left: theme.spacing(3), // adjust spacing from the left
    background: '#fff',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${timbalGrey[300]}`,
    padding: 2,
    zIndex: 1000,
    width: '600px', // Fixed width to match the search bar
    '&:focus-within': {
        borderColor: '#5012cb',
    },
    '&:hover': {
        borderColor: '#5012cb',
    },
    '&:hover .MuiSvgIcon-root': {
        color: '#5012cb',
    },
    '&:focus-within .MuiSvgIcon-root': {
        color: '#5012cb',
    },
}));

const CustomTextField = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 6,
        border: 'none',
        width: '100%', // Full width within the container
        borderColor: timbalGrey[300],
        fontSize: 16,
        padding: '8px 10px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // placeholder color
        '&::placeholder': {
            color: timbalGrey[700],
            fontWeight: 500,
        },
    },
}));

const FloatingSearchBar = () => {

    const { getExploreSteps, getStepsTags } = useApi();
    const [inputFocused, setInputFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState(''); // Manage the input value
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

    const [tagsList, setTagsList] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const anchorRef = useRef(null);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(true);

    const { addStep, bodyCanva } = useWorkflow();
    const { drawerWidth } = useRightDrawer();
    const { setCenter } = useReactFlow();

    const inputRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchQuery]);

    useEffect(() => {
        if (inputFocused) {
            handleSearch(debouncedSearchQuery, selectedTags);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery, selectedTags, inputFocused]);

    async function loadTags() {
        const resp = await getStepsTags();
        if (resp.success) {
            setTagsList(resp.success.tags);
        }
    }

    async function handleSearch(query, tags) {
        setLoadingSearch(true);
        const tagsIds = tags.length > 0 ? tags : null;
        const resp = await getExploreSteps({ searchQuery: query, tagsIds });
        if (resp.success) {
            setResults(resp.success.stepsVersions);
        }
        setLoadingSearch(false);
    }

    const handleAddStep = async (versionId, xPos, yPos) => {
        setLoading(true);
        setSearchQuery('');
        setInputFocused(false);  // Clear the input by resetting the state
        let x, y;
        // Find the node with the maximum x position
        const nodes = bodyCanva.nodes;
        const maxXNode = nodes.reduce((max, node) => {
            if (!max || node.position.x > max.position.x) {
                return node;
            }
            return max;
        }, null);

        // Set position for the new node on the right side of the rightmost node
        x = maxXNode ? maxXNode.position.x + 520 : 0;
        y = maxXNode ? maxXNode.position.y : 0;

        await addStep({ version_id: versionId, x: xPos || x, y: yPos || y });

        // Center the view on the newly added node
        setCenter(x + 210, y, { zoom: 0.75 });

        // Blur the input after adding the step
        if (inputRef.current) {
            inputRef.current.blur();
        }
        setLoading(false);
    };

    const handleFocus = async () => {
        setInputFocused(true);
        await loadTags();
        await handleSearch(searchQuery, selectedTags);
    };

    const handleOnBlur = () => {
        // Use setTimeout to delay the execution of this function
        setTimeout(() => {
            setInputFocused(false);
            setSearchQuery('');
            setSelectedTags([]);
        }, 200);  // 200ms delay
    };

    const handleSelectTag = (tag) => {
        setSelectedTags(prevTags => {
            if (prevTags.includes(tag.id)) {
                return prevTags.filter(t => t !== tag.id);
            } else {
                return [...prevTags, tag.id];
            }
        });
    };

    const SearchTagElement = ({ tag }) => {
        return (<Box
            onClick={() => handleSelectTag(tag)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                pr: 1,
                pl: 1,
                pt: 0.25,
                pb: 0.25,
                backgroundColor: timbalGrey[300],
                outline: selectedTags.includes(tag.id) ? `1px solid #5012cb` : 'none',
                outlineOffset: '-1px',
                borderRadius: '10px',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: timbalGrey[700],
                },
                ...tag.style
            }}>
            {tag.iconUrl && <Avatar variant="square" src={tag.iconUrl} sx={{ height: 15, width: 15 }} />}
            <Typography variant="caption">{tag.name}</Typography>
        </Box>)
    };

    const ExploreResultItem = ({ result }) => {
        return (
            <Box sx={{ display: 'flex', p: 2, alignContent: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <AvatarGroup
                            max={3}
                            size="small"
                            sx={{
                                mr: result.iconsUrls && result.iconsUrls.length > 0 ? 1 : 0,
                                '& .MuiAvatarGroup-avatar': {
                                    height: 30,
                                    width: 30,
                                },
                            }}
                            renderSurplus={(surplus) =>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    width: 30,
                                    bgcolor: timbalGrey[300],
                                    borderRadius: '50%',
                                }}>
                                    <Typography variant="caption" sx={{ color: 'black', fontWeight: 'bold' }}>
                                        +{surplus}
                                    </Typography>
                                </Box>
                            }
                        >
                            {result.iconsUrls && result.iconsUrls.map((icon, index) => (
                                <Avatar src={icon} key={`icon-${index}-result-${result.id}`} sx={{ height: 30, width: 30 }} />
                            ))}
                        </AvatarGroup>
                        <Typography variant="body1" sx={{ fontWeight: 700 }}>{result.name}</Typography>
                    </Box>
                    <Typography variant="body2" sx={{
                        color: timbalGrey[700],
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                    }}>{result.description}</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                        {result.tags.map((tag, index) => (
                            <SearchTagElement key={`tag-${index}-result-${result.id}`} tag={tag} />
                        ))}
                        <Typography variant="caption" sx={{ color: timbalGrey[700] }}>{`Updated ${millisToDate(result.updatedAt)}`}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <BoltOutlined sx={{ color: timbalGrey[700], height: 18, width: 18 }} />
                            <Typography variant="caption" sx={{ color: timbalGrey[700] }}>{`${prettyInt(result.runsCount)} runs`}</Typography>
                        </Box>

                    </Box>

                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ alignContent: 'center' }}>
                    <MainButtonCanvas onClick={() => handleAddStep(result.versionId)} startIcon={<AddOutlined />}>Add</MainButtonCanvas>
                </Box>
            </Box>
        );
    };

    return (
        <Box>
            {loading && <LoaderWithText text="Adding step..." />}
            <SearchBarContainer ref={anchorRef} sx={{ maxWidth: `calc(95% - ${drawerWidth}px)` }}>
                <SearchRounded sx={{ color: timbalGrey[300], pl: 1.5 }} />
                <CustomTextField
                    inputRef={inputRef}
                    variant="outlined"
                    fullWidth
                    placeholder="Add a step..."
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                    }}
                    onFocus={handleFocus}
                    onBlur={handleOnBlur}
                />
            </SearchBarContainer>
            <Popper
                open={inputFocused && results}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                style={{ zIndex: 1200, width: anchorRef.current ? anchorRef.current.offsetWidth : 'auto' }}
            >
                <Paper
                    style={{
                        marginTop: 8,
                        border: `1px solid ${timbalGrey[300]}`,
                        borderRadius: '6px',
                        width: '100%', // Same width as the search container
                        boxShadow: 'none',
                        maxHeight: '80vh', // Fixed height for the dropdown
                        overflowY: 'auto',
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    {tagsList.length > 0 && <Box sx={{
                        display: 'flex',
                        gap: 1,
                        flexWrap: 'wrap',
                        p: 2,
                    }}>
                        {tagsList.map((tag, index) => (
                            <SearchTagElement tag={tag} key={`tag-${index}`} />
                        ))}</Box>}
                    <List>
                        {
                            loadingSearch ?
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress size={20} thickness={3} sx={{ color: timbalGrey[700] }} />
                                    <Typography variant="body2" sx={{ color: timbalGrey[700], p: 2 }}>Searching</Typography>
                                </Box>
                                : results.length > 0 ?
                                    results.map((result, index) => (
                                        <ExploreResultItem key={index} result={result} />
                                    )) :
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="body2" sx={{ color: timbalGrey[700], p: 2 }}>No results found</Typography>
                                    </Box>
                        }
                    </List>
                </Paper>
            </Popper>
        </Box >
    );


};

export default FloatingSearchBar;
