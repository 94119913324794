import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '6px',
        textAlign: 'end',
        padding: '8px 12px',
        // Remove the default appearance of spin buttons in number input fields
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            marginLeft: '10px',
            WebkitAppearance: 'auto',
            opacity: 0.5,
            MozAppearance: 'textfield', // Ensures cross-browser consistency
        },
        // To prevent default behavior on Firefox, which doesn't support -webkit
        '&[type=number]': {
            MozAppearance: 'textfield',
        },
    },
}));

export { BootstrapInput };