// src/utils/websocket.js
class WebSocketService {
    static instance = null;

    constructor(url, getSession) {
        this.socketRef = null;
        this.url = url;
        this.callbacks = {};
        this.getSession = getSession;
        this.intentionalDisconnect = false;
        this.connect();
    }

    static getInstance(url, getSession) {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService(url, getSession);
        }
        return WebSocketService.instance;
    }

    connect = () => {
        if (this.socketRef && this.socketRef.readyState === WebSocket.OPEN) {
            console.log("WebSocket connection already exists");
            return;
        }

        const token = localStorage.getItem('x-auth-token');
        const hidratatedUrl = `${this.url}&x-auth-token=${token}`;

        this.socketRef = new WebSocket(hidratatedUrl);

        this.socketRef.onopen = () => {
            console.log("WebSocket connected");
            this.handleOnOpen();
        };

        this.socketRef.onmessage = (e) => {
            this.handleNewMessage(e.data);
        };

        this.socketRef.onerror = (e) => {
            console.log("WebSocket error:", e.message);
        };

        this.socketRef.onclose = async (event) => {
            if (!this.intentionalDisconnect) {
                await this.getSession();
                console.log("WebSocket disconnected unexpectedly. Reconnecting...");
                this.connect();
            } else {
                console.log("WebSocket disconnected intentionally.");
                this.intentionalDisconnect = false;
            }
        };
    };

    handleNewMessage = (data) => {
        Object.keys(this.callbacks).forEach((key) => {
            if (key === 'onOpen') return;
            this.callbacks[key](data);
        });
    };

    handleOnOpen = () => {
        if (this.callbacks['onOpen']) {
            this.callbacks['onOpen']();
        }
    };

    addCallbacks = (callbacks) => {
        this.callbacks = { ...this.callbacks, ...callbacks };
    };

    sendMessage = (data) => {
        if (this.socketRef && this.socketRef.readyState === WebSocket.OPEN) {
            this.socketRef.send(data);
        } else {
            console.error("WebSocket is not connected. Message not sent.");
        }
    };

    disconnect = () => {
        if (this.socketRef) {
            this.intentionalDisconnect = true;
            this.socketRef.close();
            this.socketRef = null;
        }
    };
}

export default WebSocketService;
