// CanvaPage.js

import React from 'react';
import { Box } from '@mui/material';
import CustomAppBar from './components/CustomAppBar';
import { WorkflowPage } from './workflow/WorkflowPage';
import useDocumentTitle from 'utils/useDocumentTitle';
import { WorkflowContextProvider } from './workflow/WorkflowContext';
import { useWorkflow } from './workflow/WorkflowContext';
import { timbalGrey } from 'components/CustomColors';
import { WebSocketProvider } from './workflow/WebSocketProvider';
import { SaveFlowContextProvider } from './workflow/SaveFlowContext';

const MainPageChild = () => {

    const { flow } = useWorkflow();

    useDocumentTitle(flow ? flow.name : 'Loading...');

    if (!flow) {
        return null;
    }

    return (

        <Box display="flex" flexDirection="column" height="100vh" minWidth={`calc(650px + 375px)`}>
            {/* Top Navbar */}
            <Box height={52} sx={{ borderBottom: `1px solid ${timbalGrey[300]}`, }}>
                <SaveFlowContextProvider>
                    <CustomAppBar />
                </SaveFlowContextProvider>
            </Box>
            {/* Workflow Page */}
            <WorkflowPage />
        </Box>

    );
};

const MainPage = () => {

    return (
        <WebSocketProvider>
            <WorkflowContextProvider>
                <MainPageChild />
            </WorkflowContextProvider>
        </WebSocketProvider>);
};

export default MainPage;
