import React, { useState } from 'react';
import { Box } from '@mui/material';
import { BootstrapInput } from './components/BootstrapNumberInput';
import InputHeader from './components/InputHeader';
import { timbalGrey } from 'components/CustomColors';
import { Typography } from '@mui/material';



const NumberInput = ({ title, description, minimum = -Infinity, maximum = Infinity, step = 1, initialValue, onChange, name, disabled }) => {
    const [value, setValue] = useState(initialValue ?? null);
    const [error, setError] = useState(false);

    const handleInputChange = (event) => {
        const newValue = event.target.value === '' ? '' : Number(event.target.value);
        setValue(newValue);
        if (newValue >= minimum && newValue <= maximum) {
            setError(false);
        } else {
            setError(true);
        }
        if (onChange && !error) {
            onChange(event);
        }
    }

    const boundaries = (() => {
        if (minimum === -Infinity && maximum === Infinity) {
            return null;
        } else if (minimum === -Infinity) {
            return `max: ${maximum}`;
        } else if (maximum === Infinity) {
            return `min: ${minimum}`;
        } else {
            return `min: ${minimum}, Max: ${maximum}`;
        }
    });

    return (
        <Box sx={{ display: "flex", flexDirection: 'column', flexalignItems: "center" }} gap={1}>
            <InputHeader title={title} description={description} boundaries={boundaries()} name={name} />
            <BootstrapInput
                value={value}
                name={name}
                size="small"
                onChange={handleInputChange}
                disabled={disabled}
                error={error}
                inputProps={{
                    step: step,
                    min: minimum,
                    max: maximum,
                    type: 'number',
                    lang: 'en-US',
                    'aria-labelledby': 'input-slider',
                }}
                sx={{
                    '& .MuiInputBase-input': {
                        borderColor: error ? 'red' : timbalGrey[300],
                        '&:focus': {
                            borderColor: error ? 'red' : '#5012cb !important',
                        }, // Change border color if there's an error
                    },

                }}
            />
            {error && (
                <Typography variant="caption" color="error">
                    {minimum !== -Infinity && value < minimum && `Minimum value is ${minimum}.`}
                    {maximum !== Infinity && value > maximum && `Maximum value is ${maximum}.`}
                </Typography>
            )}
        </Box >
    );
}

export default NumberInput;
