// components/CustomAppBar.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Breadcrumbs, Link, IconButton, CircularProgress } from '@mui/material';
import { BoltOutlined, HistoryRounded, KeyboardArrowDownOutlined, PlayArrowOutlined } from '@mui/icons-material';
import { useWorkflow } from '../workflow/WorkflowContext';
import { SecondaryButtonCanvas, MainButtonCanvas } from 'components/CustomButtons';
import { timbalGrey } from 'components/CustomColors';
import CanvaRunsModal from './CanvaRunsModal';
import InputsTooltip from 'components/inputs/components/InputsTooltip';
import DeployModal from './DeployModal';
import NewDeployModal from './NewDeployModal';
import { useSaveFlow } from '../workflow/SaveFlowContext';

const CustomAppBar = () => {

    const { flow, runFlow, runningFlow } = useWorkflow();

    const { savingFlow, saveFlow } = useSaveFlow();

    const [openRunsDialog, setOpenRunsDialog] = useState(false);
    const [openDeployDialog, setOpenDeployDialog] = useState(false);
    const [openNewDeployDialog, setOpenNewDeployDialog] = useState(false);

    const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
    const modifierKey = isMac ? '⌘' : 'Ctrl+';

    const handleRunFlow = async () => {
        if (!runningFlow) {
            await runFlow();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            const isModifierKeyPressed = isMac ? event.metaKey : event.ctrlKey;

            if (isModifierKeyPressed) {
                if (event.key === 's') {
                    event.preventDefault();
                    saveFlow();
                } else if (event.key === 'Enter') {
                    event.preventDefault();
                    handleRunFlow();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box height={52} sx={{ display: 'flex', pl: 2, pr: 2, alignItems: 'center', zIndex: 1500, gap: 2, backgroundColor: 'white' }}>
            <Box display={"flex"} gap={2}>
                <img
                    alt="Logo"
                    src="/icon_logo.png"
                    width={40}
                    height={40}
                />
            </Box>

            <Box gap={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <Breadcrumbs sx={{ color: '#000' }}>
                    <Link underline="hover" color="inherit" href="/">
                        <Typography variant="body2" fontWeight={400} >
                            My Flows
                        </Typography>
                    </Link>
                    <Typography variant="body2" fontWeight={700}>
                        {flow.name}
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box flexGrow={1} />
            <Box sx={{ display: 'flex' }} gap={1}>
                {/* <InputsTooltip title="Do you want more credits UPGRADE NOW rata malaya!!!" placement="bottom">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'end', height: 35, pr: 2, gap: 0.25 }}>
                        <LinearProgress
                            variant="determinate"
                            value={(3 / 50) * 100}
                            sx={{
                                height: 8,
                                width: 120,
                                borderRadius: '6px',
                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: timbalGrey[300],
                                },
                                [`& .${linearProgressClasses.bar}`]: {
                                    backgroundColor: timbalGrey[700],
                                },
                            }} />
                        <Typography variant="body2" sx={{ color: timbalGrey[700], fontWeight: 500 }}>3/50 credits used</Typography>
                    </Box>
                </InputsTooltip> */}
                <IconButton
                    id="card-button"
                    onClick={() => setOpenRunsDialog(true)}
                    sx={{
                        p: 0.5,
                        height: 35,
                        width: 35,
                        backgroundColor: timbalGrey[300],
                        borderRadius: '6px',
                        '&:hover': {
                            backgroundColor: timbalGrey[700],
                        },
                    }}
                >
                    <HistoryRounded sx={{ fontSize: 24, color: 'black' }} />
                </IconButton>
                <InputsTooltip
                    title={`Save ${modifierKey}S`}
                    placement="bottom"
                >
                    <SecondaryButtonCanvas color="primary"
                        startIcon={
                            savingFlow ?
                                <CircularProgress size={15} thickness={5} sx={{ color: timbalGrey[700] }} />
                                : null
                        }
                        disableElevation
                        variant='contained'
                        disabled={savingFlow}
                        onClick={() => saveFlow()}
                    >
                        {savingFlow ? 'Saving' : (

                            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                <Typography variant="body2" fontWeight={500}>Save</Typography>
                            </Box>


                        )}
                    </SecondaryButtonCanvas>
                </InputsTooltip>
                <InputsTooltip
                    title={`Run ${modifierKey}↵ enter`}
                    placement="bottom"
                >
                    <SecondaryButtonCanvas color="primary"
                        startIcon={
                            runningFlow ?
                                <CircularProgress size={15} thickness={5} sx={{ color: timbalGrey[700] }} />
                                : <PlayArrowOutlined sx={{ height: 25, width: 25 }} />
                        }
                        disableElevation
                        variant='contained'
                        disabled={runningFlow}
                        onClick={() => handleRunFlow()}
                    >
                        {runningFlow ? 'Running' : (
                            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                <Typography variant="body2" fontWeight={500}>Run</Typography>
                            </Box>
                        )}
                    </SecondaryButtonCanvas>
                </InputsTooltip>
                <MainButtonCanvas
                    color="primary"
                    startIcon={<BoltOutlined sx={{ fheight: 25, width: 25 }} />}
                    endIcon={
                        <KeyboardArrowDownOutlined
                            onClick={(event) => {
                                setOpenDeployDialog(true);
                                event.stopPropagation();
                            }}
                            sx={{ height: 25, width: 25 }} />
                    }
                    disableElevation
                    variant='contained'
                    onClick={() => setOpenNewDeployDialog(true)}
                >
                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                        <Typography variant="body2" fontWeight={500}>Deploy</Typography>
                    </Box>
                </MainButtonCanvas>
                <CanvaRunsModal openRunsDialog={openRunsDialog} setOpenRunsDialog={setOpenRunsDialog} />
                <DeployModal openDeployDialog={openDeployDialog} setOpenDeployDialog={setOpenDeployDialog} setOpenNewDeployDialog={setOpenNewDeployDialog} />
                <NewDeployModal openNewDeployDialog={openNewDeployDialog} setOpenNewDeployDialog={setOpenNewDeployDialog} setOpenDeployDialog={setOpenDeployDialog} />
            </Box>
        </Box >
    );
};

export default CustomAppBar;
