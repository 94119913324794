import { Box, Switch } from "@mui/material";
import React from "react";
import { timbalGrey } from "components/CustomColors";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import InputHeader from "./components/InputHeader";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#5012cb',
                opacity: 1,
                border: 0,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        boxShadow: 'none',
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        backgroundColor: timbalGrey[700],
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const BoolInput = ({ title, description, initialValue, onChange, name, disabled }) => {
    const [value, setValue] = useState(initialValue ?? false);

    const handleToggleChange = (event) => {
        setValue(event.target.checked);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
            <InputHeader title={title} description={description} name={name} />
            <Box flexGrow={1} />
            <IOSSwitch
                name={name}
                disabled={disabled}
                checked={value == null ? false : value}
                onChange={handleToggleChange}
            />
        </Box>
    );
}

export default BoolInput;