import React, { useMemo, useCallback, useState, useEffect } from 'react';
import ReactFlow, {
    Background,
    Controls,
    ReactFlowProvider,
    applyNodeChanges
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomNodePreview from './CustomNodePreview';
import { timbalGrey } from 'components/CustomColors';
import CustomEdgePreview from './CustomEdgePreview';

const ReactFlowCanvasPreview = ({ initNodes = [], initEdges = [], setSelectedNode = () => { } }) => {
    const [nodes, setNodes] = useState(initNodes);
    const [edges, setEdges] = useState(initEdges);

    useEffect(() => {
        setNodes(initNodes);
        setEdges(initEdges);
    }, [initNodes, initEdges]);

    const onPaneClick = useCallback(() => {
        setNodes((nds) =>
            nds.map((node) => ({
                ...node,
                selected: false
            }))
        );
        setSelectedNode(null);
    }, [setNodes, setSelectedNode]);

    const onNodesChange = useCallback((changes) => {
        for (const change of changes) {
            if (change.type === 'select' && change.selected) {
                setSelectedNode(nodes.find(node => node.id === change.id));
            }
        }
        setNodes((nds) => applyNodeChanges(changes, nds));
    }, [setNodes, setSelectedNode, nodes]);

    const nodeTypes = useMemo(
        () => ({
            customNode: CustomNodePreview
        }),
        [],
    );

    const edgeTypes = useMemo(
        () => ({
            customEdge: CustomEdgePreview
        }),
        [],
    );

    return (
        <ReactFlowProvider>
            <ReactFlow
                style={{
                    borderRadius: '6px',
                    border: `1px solid ${timbalGrey[300]}`
                }}
                id={`react-flow-canvas-preview-${Math.random().toString(36).substring(2, 15)}`}
                nodes={nodes}
                edges={edges}
                zoomOnScroll={false}
                panOnScroll={false}
                minZoom={0.1}
                maxZoom={2.0}
                fitView={true}
                deleteKeyCode={['Backspace', 'Delete']}
                proOptions={{ hideAttribution: true }}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                defaultEdgeOptions={{ type: 'customEdge' }}
                connectionLineType='smoothstep'
                edgesUpdatable={false}
                nodesDraggable={false}
                nodesConnectable={false}
                onPaneClick={onPaneClick}
                onNodesChange={onNodesChange}
            >
                <Controls showInteractive={false} />
                <Background color={timbalGrey[300]} size={2} style={{ backgroundColor: '#fefdf7' }} />
            </ReactFlow>
        </ReactFlowProvider>
    );
};

export default ReactFlowCanvasPreview;
