import React from 'react';
import { Box } from '@mui/material';
import FloatingSearchBar from './components/FloatingSearchBar';
import RightDrawer from './components/RightDrawer';
import ReactFlowCanvas from './components/ReactFlowCanvas';
import ContextMenu from './components/ContextMenu';
import { DataMissingContextProvider } from './DataMissingContext';
import { RightDrawerContextProvider } from './RightDrawerProvider';
import { ReactFlowProvider } from 'reactflow';



const WorkflowPage = () => {

    return (
        <Box>
            <RightDrawerContextProvider>
                <ReactFlowProvider>
                    <FloatingSearchBar />
                    <DataMissingContextProvider>
                    <Box display="flex" flexGrow={1} overflow="hidden"> {/* Margin top to account for AppBar height */}
                        {/* Flow Canvas */}
                        <Box flexGrow={1} overflow="auto">
                            <ReactFlowCanvas />
                        </Box>
                        {/* Right Drawer */}
                        <RightDrawer />
                        {/* Context Menu */}
                        <ContextMenu />
                    </Box>
                    </DataMissingContextProvider>
                </ReactFlowProvider>
            </RightDrawerContextProvider>
        </Box>
    );
}

export { WorkflowPage };