import { Box, IconButton, Typography } from "@mui/material";
import { CloseOutlined, PowerOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useWorkflow } from "canva/workflow/WorkflowContext";
import InputHeader from "components/inputs/components/InputHeader";
import { timbalGrey } from "components/CustomColors";
import ConnectableInputsModal from "./ConnectableInputsModal";

const ConnectableInput = ({ children, sx }) => {
    const [openConnectDialog, setOpenConnectDialog] = useState(false);
   
    const { selectedNode, bodyCanva, globalInputs, removeDataValue } = useWorkflow();

    const handleCloseConnectDialog = () => {
        setOpenConnectDialog(false);
    }

    const handleRemoveConnect = () => {
        removeDataValue({
            step_id: selectedNode.id,
            step_param_name: children.props.name,
        });
    }

    const handleRemoveConnectGlobal = () => {
        removeDataValue({
            step_id: selectedNode.id,
            step_param_name: children.props.name,
            client_message_id: 'set_inputs_outputs'
        });
    }

    const globalMapping = globalInputs?.mappings?.find(mapping => mapping.step_id === selectedNode.id && mapping.step_param_name === children.props.name) ?? null;

    if (globalMapping) {
        const input_name = globalMapping.input_name;
        const globalParamName = globalInputs.schema.properties[input_name].title;

        return (
            <Box>
                <Box sx={{ display: "flex", gap: 1, alignItems: 'center' }}>
                    <InputHeader {...children.props} status={null} />
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton sx={{ p: 0.5 }} onClick={handleRemoveConnectGlobal}>
                        <CloseOutlined />
                    </IconButton>
                </Box>
                <Typography variant="caption" sx={{ color: timbalGrey[700] }}>
                    {`Set as global input '${globalParamName}'`}
                </Typography>
            </Box>
        )
    }

    if (children.props.initialValue?.type && children.props.initialValue.type === 'map') {
        const ancestorNode = bodyCanva.nodes.find(node => node.id === children.props.initialValue.ancestor_id);
        let title = null;
        if (ancestorNode && children.props.initialValue.ancestor_return_param_name) {
            title = ancestorNode.data.outputs_schema.properties[children.props.initialValue.ancestor_return_param_name].title;
        }

        return (
            <Box>
                <Box sx={{ display: "flex", gap: 1, alignItems: 'center' }}>
                    <InputHeader {...children.props} status={null} />
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton sx={{ p: 0.5 }} onClick={handleRemoveConnect}>
                        <CloseOutlined />
                    </IconButton>
                </Box>
                <Typography variant="caption" sx={{ color: timbalGrey[700] }}>
                    {`Connected to ${title || 'result'} from step ${ancestorNode.data.name}`}
                </Typography>
            </Box>
        )
    }

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ flexGrow: 1, alignContent: 'center' }}>
                    {children}
                </Box>
                <Box sx={{ display: 'block', alignContent: 'center', mt: 3, ...sx, }}>
                    <IconButton sx={{ p: 0.5 }} onClick={() => setOpenConnectDialog(true)}>
                        <PowerOutlined />
                    </IconButton>
                </Box>
            </Box>
            <ConnectableInputsModal
                open={openConnectDialog}
                onClose={handleCloseConnectDialog}
                inputTitle={children.props.title}
                stepId={selectedNode.id}
                stepParamName={children.props.name}
            />
        </>
    );
};

export { ConnectableInput };
