import { timbalGrey } from 'components/CustomColors';
import React from 'react';
import { SmoothStepEdge } from 'reactflow';

const CustomEdgePreview = ({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, style = {}, selected }) => {

    const edgeStyle = {
        stroke: selected ? '#5012cb' : timbalGrey[300],
        strokeWidth: 2,
        ...style
    };

    return (
        <SmoothStepEdge
            sourceX={sourceX}
            sourceY={sourceY}
            targetX={targetX}
            targetY={targetY}
            sourcePosition={sourcePosition}
            targetPosition={targetPosition}
            style={edgeStyle} />
    );
};

export default CustomEdgePreview;
