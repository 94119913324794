import { createContext, useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { DEBUG } from 'utils/miscelanea';
import LoaderWithText from 'components/LoaderWithText';
import { enqueueSnackbar } from 'notistack';
import { useWebSocket } from './WebSocketProvider';
import { useApi } from 'api/ApiContext';
import { useNavigate } from 'react-router-dom';

const WorkflowContext = createContext();

export const WorkflowContextProvider = ({ children }) => {

    const { id } = useParams();
    const [flow, setFlow] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null);
    const [bodyCanva, setBodyCanva] = useState({ nodes: [], edges: [] });

    const [contextMenu, setContextMenu] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const messagesQueue = useRef({});
    const [loading, setLoading] = useState(true);
    const [runningFlow, setRunningFlow] = useState(false);

    const bodyCanvaRef = useRef(null);
    const [globalInputs, setGlobalInputs] = useState(null);
    const [globalOutputs, setGlobalOutputs] = useState(null);

    const { socketService, MessageCode, MessageType } = useWebSocket();
    const { postFlowsName, postFlowsDescription, getFlow } = useApi();

    useEffect(() => {
        if (!socketService) return;
        // Add your message handlers
        socketService.addCallbacks({
            onOpen: () => {
                getCanvaInputs();
                getCanvaOutputs();
                sendSync();
            },
            onMessage: (data) => {
                digestMessage(data);
            },
        });
        // Cleanup when the component unmounts
        return () => {
            socketService.addCallbacks({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketService]);


    useEffect(() => {
        if (location.state && location.state.flow) {
            setFlow(location.state.flow);
        } else {
            fetchFlow();
            async function fetchFlow() {
                const resp = await getFlow(id);
                if (resp.success) {
                    setFlow(resp.success.flow);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (bodyCanva) {
            bodyCanvaRef.current = bodyCanva;
        }
    }, [bodyCanva]);

    // Socket mesages
    const digestMessage = (message) => {
        try {
            const { status, body, code, client_message_id } = JSON.parse(message);
            const user = JSON.parse(localStorage.getItem('x-user'));
            if (DEBUG) {
                console.log('Received message from server:', { status, body, code, client_message_id });
            }
            if (client_message_id && messagesQueue.current[client_message_id]) {
                messagesQueue.current[client_message_id].res = { status, body, code, client_message_id };
            }
            if (status === 200) {
                switch (code) {
                    case MessageCode.SYNC:
                        if (client_message_id === 'set_data') return;
                        if (client_message_id === 'set_inputs_outputs') {
                            getCanvaInputs();
                            getCanvaOutputs();
                            if (selectedNode) {
                                getNodeData({ step_id: selectedNode.id });
                            }
                            return;
                        }
                        body.nodes.forEach(node => {
                            if (body.data_missing[node.id]) {
                                node.data.status = { code: 'warning', keys: body.data_missing[node.id] };
                            } else if (node.data.outputs_data.type === 'error') {
                                node.data.status = { code: 'error', keys: [] };
                            } else {
                                node.data.status = { code: 'success', keys: [] };
                            }
                            if (user.id.toString() === node.lock) {
                                node.selected = true;
                                getNodeData({ step_id: node.id });
                            }
                        });
                        setBodyCanva((prev) => { return body });
                        setLoading(false);
                        break;
                    case MessageCode.STEP_AVAILABLE_MAPPINGS:
                        break;
                    case MessageCode.STEP_START:
                        setBodyCanva(
                            (prev) => {
                                const newBody = { ...prev };
                                newBody.nodes.forEach(node => {
                                    if (node.id === body.step_id) {
                                        node.data.status = { code: 'running', keys: [] };
                                    }
                                });
                                newBody.edges.forEach(edge => {
                                    if (edge.source === body.step_id) {
                                        edge.animated = true;
                                    }
                                    if (edge.target === body.step_id) {
                                        edge.animated = false;
                                    }
                                });
                                return newBody;
                            });
                        break;
                    case MessageCode.STEP_OUTPUT:
                        setBodyCanva(
                            (prev) => {
                                const newBody = { ...prev };
                                newBody.nodes.forEach(node => {
                                    if (node.id === body.step_id) {
                                        if (node.data.outputs_schema.type === 'object') {
                                            node.data.outputs_data.value = { ...node.data.outputs_data.value, ...body.step_output };
                                        } else {
                                            node.data.outputs_data.value = body.step_output;
                                        }
                                        node.data.status = { code: 'success', keys: [] };
                                    }
                                });
                                return newBody;
                            }
                        );
                        break;
                    case MessageCode.STEP_OUTPUT_CHUNK:
                        setBodyCanva(
                            (prev) => {
                                const newBody = { ...prev };
                                newBody.nodes.forEach(node => {
                                    if (node.id === body.step_id) {
                                        if (body.is_first_chunk) {
                                            if (node.data.outputs_schema.type === 'object') {
                                                node.data.outputs_data.value = { ...node.data.outputs_data.value, ...body.step_output_chunk };
                                            }
                                            else {
                                                node.data.outputs_data.value = body.step_output_chunk;
                                            }
                                        } else {
                                            if (node.data.outputs_schema.type === 'object') {
                                                Object.entries(body.step_output_chunk).map(([key, value]) =>
                                                    node.data.outputs_data.value[key] += value
                                                );
                                            } else {
                                                node.data.outputs_data.value += body.step_output_chunk;
                                            }
                                        }
                                    }
                                });
                                return newBody;
                            }
                        );
                        break;
                    case MessageCode.STEP_LOCK:
                        bodyCanvaRef.current.nodes.forEach(node => {
                            if (node.id === body.step_id) {
                                node.lock = body.user_id;
                            }
                        });
                        getNodeData({ step_id: body.step_id });
                        break;
                    case MessageCode.STEP_UNLOCK:
                        bodyCanvaRef.current.nodes.forEach(node => {
                            if (node.id === body.step_id) {
                                node.lock = null;
                            }
                        });
                        let selectedNodes = bodyCanvaRef.current.nodes.filter(node => node.lock === user.id.toString());
                        if (selectedNodes.length === 0) {
                            getCanvaInputs();
                            getCanvaOutputs();
                            setSelectedNode(null);
                        }
                        break;
                    case MessageCode.FLOW_OUTPUT:
                        setRunningFlow(false);
                        _unSelectAllNodes();
                        getCanvaOutputs();
                        getCanvaInputs();
                        sendSync();
                        break;
                    case MessageCode.STEP_DATA:
                        let node = bodyCanvaRef.current.nodes.find(node => node.id === body.step_id);
                        let newNode = { ...node, data: { ...node.data, ...body } };
                        setSelectedNode((n) => {
                            return newNode;
                        });
                        break;
                    case MessageCode.FLOW_INPUTS:
                        setGlobalInputs((prev) => body);
                        break;
                    case MessageCode.FLOW_OUTPUTS:
                        setGlobalOutputs((prev) => body);
                        break;
                    default:
                        //TODO
                        break;
                }
            } else if (status >= 400) {
                enqueueSnackbar(body?.message ?? 'Unknown error occurred', { variant: 'error', autoHideDuration: 5000 });
                setRunningFlow(false);
                _unSelectAllNodes();
                getCanvaOutputs();
                getCanvaInputs();
                sendSync();
            }
        } catch (e) {
            console.log('Error:', e);
        }
    }

    const _unSelectAllNodes = async () => {
        setBodyCanva(async (prev) => {
            const newBody = { ...prev };
            for (const node of newBody.nodes) {
                if (node.selected) {
                    await unlockStep({ step_id: node.id });
                }
                node.selected = false;
            }
            return newBody;
        });
        setSelectedNode(null);
    }

    const sendMessage = ({ action, parameters, client_message_id }) => {
        if (!socketService) return;
        if (DEBUG) {
            console.log('Sending message to server:', { action, parameters, client_message_id });
        }
        socketService.sendMessage(JSON.stringify({ action, parameters, client_message_id }));
        //websocket.current.send(JSON.stringify({ action, parameters, client_message_id }));
        if (client_message_id) {
            messagesQueue.current[client_message_id] = { req: { action, parameters, client_message_id } };
        }
    }

    const sendSync = async () => {
        const uuid = Math.random().toString(36).substring(7);
        sendMessage({ action: MessageType.SYNC, parameters: {}, client_message_id: uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    // {"action": "add_step", "parameters": {"source": {"version_id": "2"}, "reactflow_node_position":{"x":37.37,"y":37.37}}}

    const addStep = async ({ version_id, x, y, data = null, client_message_id } = {}) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            source: { version_id },
            reactflow_node_position: { x, y },
            reactflow_node_data: data,
        };
        sendMessage({ action: MessageType.ADD_STEP, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    // {"action": "reposition_step", "parameters": {"step_id": "1", "reactflow_node_position": {"x": 0, "y": 0}}}

    const repositionStep = ({ step_id, x, y, client_message_id }) => {
        const parameters = {
            step_id,
            reactflow_node_position: { x, y }
        };
        sendMessage({ action: MessageType.REPOSITION_STEP, parameters, client_message_id });
    }

    // {"action": "remove_step", "parameters": {"step_id": "2"}}

    const removeStep = async ({ step_id, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id,
        };
        await _unSelectAllNodes();
        sendMessage({ action: MessageType.REMOVE_STEP, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    // {"action": "add_link", "parameters":{"step_id":"1", "next_step_id":"2"}}

    const addLink = ({ step_id, next_step_id, client_message_id }) => {
        const parameters = {
            step_id,
            next_step_id,
            reactflow_edge_type: 'customEdge'
        };
        sendMessage({ action: MessageType.ADD_LINK, parameters, client_message_id });
    }

    // {"action": "remove_link", "parameters": {"link_id": "1-2"}}

    const removeLink = ({ link_id, client_message_id }) => {
        const parameters = {
            link_id,
        };
        sendMessage({ action: MessageType.REMOVE_LINK, parameters, client_message_id });
    }

    // {“action”: “rename_step”, “parameters”: {“step_id”: “step_id”, “name”: “new name”}}

    const renameStep = ({ step_id, name, client_message_id }) => {
        const parameters = {
            step_id,
            name
        };
        sendMessage({ action: MessageType.RENAME_STEP, parameters, client_message_id });
    }

    // {"action": "get_available_mappings", "parameters":{"step_id":"4", "step_param_name": "image"}}

    const getAvailableMappings = async ({ step_id, step_param_name, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id,
            step_param_name
        };
        sendMessage({ action: MessageType.GET_AVAILABLE_MAPPINGS, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "get_available_inputs_mappings”, "parameters": {“step_id”: “1”, “step_param_name”: “prompt”}}

    const getAvailableInputsMappings = async ({ step_id, step_param_name, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id,
            step_param_name
        };
        sendMessage({ action: MessageType.GET_AVAILABLE_INPUTS_MAPPINGS, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "set_data", "parameters":{"step_id":"4", "step_param_name": "height", "data": {"type": "value", "value": 512}}}

    const setDataValue = async ({ step_id, step_param_name, value, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id,
            step_param_name,
            data: { type: 'value', value }
        };
        sendMessage({ action: MessageType.SET_DATA, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "set_data", "parameters":{"step_id":"2", "step_param_name": "image", "data": {"type": "map", "ancestor_id": "1"}}}
    //{"action": "set_data", "parameters":{"step_id":"2", "step_param_name": "image", "data": {"type": "map", "ancestor_id": “1”, “ancestor_return_param_name”: “image”}}}

    const setMappingValue = async ({ step_id, step_param_name, ancestor_id, ancestor_return_param_name, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id,
            step_param_name,
            data: { type: 'map', ancestor_id, ancestor_return_param_name }
        };
        sendMessage({ action: MessageType.SET_DATA, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{“action”: “remove_data”, “parameters”: {“step_id”: str, “step_param_name”: str}}

    const removeDataValue = async ({ step_id, step_param_name, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id,
            step_param_name
        };
        sendMessage({ action: MessageType.REMOVE_DATA, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "lock_step", "parameters": {"step_id": "1"}}

    const lockStep = ({ step_id, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id
        };
        sendMessage({ action: MessageType.LOCK_STEP, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "unlock_step", "parameters": {"step_id": "1"}}
    const unlockStep = ({ step_id, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id
        };
        sendMessage({ action: MessageType.UNLOCK_STEP, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "set_input", "parameters": {"step_id":"3", "step_param_name": "prompt", "input_name": "prompt"}}
    const setGlobalInput = ({ step_id, step_param_name, input_name, client_message_id }) => {
        const parameters = {
            step_id,
            step_param_name,
            input_name
        };
        sendMessage({ action: MessageType.SET_INPUT, parameters, client_message_id });
    }

    //{"action": "set_output", "parameters": {"step_id":"4", "output_name": "image"}}
    const setGlobalOutput = ({ step_id, output_name, step_return_param_name, client_message_id }) => {
        const parameters = {
            step_id,
            output_name,
            step_return_param_name
        };
        sendMessage({ action: MessageType.SET_OUTPUT, parameters, client_message_id });
    }

    //{“action”: “remove_output”, “parameters”: {“output_name”: str}}
    const removeGlobalOutput = ({ output_name, client_message_id }) => {
        const parameters = {
            output_name
        };
        sendMessage({ action: MessageType.REMOVE_OUTPUT, parameters, client_message_id });
    }

    //{“action”: “remove_input”, “parameters”: {“input_name”: str}}
    const removeGlobalInput = ({ input_name, client_message_id }) => {
        const parameters = {
            input_name
        };
        sendMessage({ action: MessageType.REMOVE_INPUT, parameters, client_message_id });
    }

    //{"action": “run", "parameters": {"input_key_1": "value", "input_key_2": "value", "input_key_3": "value"}}

    const runFlow = async ({ client_message_id } = {}) => {
        const user = JSON.parse(localStorage.getItem('x-user'));
        const inputs = await getCanvaInputs();
        const sync = await sendSync();
        let pass = true;

        inputs.body.schema.required && inputs.body.schema.required.forEach(input => {
            if (!inputs.body.data[input]) {
                enqueueSnackbar(`Input "${inputs.body.schema.properties[input].title}" is required but not filled`,
                    { variant: 'warning', autoHideDuration: 5000 });
                pass = false;
            }
        });

        sync.body.data_missing && Object.entries(sync.body.data_missing).forEach(([key, value]) => {
            const name = sync.body.nodes.find(node => node.id === key).data.name;
            enqueueSnackbar(`Missing "${value}" for "${name}"`, { variant: 'warning', autoHideDuration: 5000 });
            pass = false;
        });

        if (!pass) {
            return;
        }

        setBodyCanva(
            (prev) => {
                const newBody = { ...prev };
                newBody.nodes.forEach(node => {
                    if (node.lock === user.id.toString()) {
                        unlockStep({ step_id: node.id });
                    }
                    node.selected = false;
                    node.data.outputs_data = {};
                    node.data.status = { code: 'none', keys: [] };
                });
                return newBody;
            });
        setRunningFlow(true);
        sendMessage({ action: MessageType.RUN, parameters: {}, client_message_id });
    }

    // {“action”: “set_show_output”, “parameters”: {“step_id”: str, “show_output”: bool}}
    const setStepShowOutput = ({ step_id, show_output, client_message_id }) => {
        const parameters = {
            step_id,
            show_output
        };
        sendMessage({ action: MessageType.SET_SHOW_OUTPUT, parameters, client_message_id });
    }

    //  {"action": "get_data", "parameters": {"step_id": "1"}}

    const getNodeData = async ({ step_id, client_message_id }) => {
        const uuid = Math.random().toString(36).substring(7);
        const parameters = {
            step_id
        };
        sendMessage({ action: MessageType.GET_DATA, parameters, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //  {"action": "get_inputs", "parameters": {}}
    const getCanvaInputs = async ({ client_message_id } = {}) => {
        const uuid = Math.random().toString(36).substring(7);
        sendMessage({ action: MessageType.GET_INPUTS, parameters: {}, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }
    //  {"action": "get_outputs", "parameters": {}}
    const getCanvaOutputs = async ({ client_message_id } = {}) => {
        const uuid = Math.random().toString(36).substring(7);
        sendMessage({ action: MessageType.GET_OUTPUTS, parameters: {}, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "get_available_inputs", "parameters": {}}

    const getAvailableInputs = async ({ client_message_id } = {}) => {
        const uuid = Math.random().toString(36).substring(7);
        sendMessage({ action: MessageType.GET_AVAILABLE_INPUTS, parameters: {}, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "get_available_outputs”, "parameters": {}}

    const getAvailableOutputs = async ({ client_message_id } = {}) => {
        const uuid = Math.random().toString(36).substring(7);
        sendMessage({ action: MessageType.GET_AVAILABLE_OUTPUTS, parameters: {}, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }

    //{"action": "set_input_value”, "parameters": {“input_name”: “prompt”, “value”: “this is a random prompt”}}

    const setInputValue = ({ input_name, value, client_message_id }) => {
        const parameters = {
            input_name,
            value
        };
        sendMessage({ action: MessageType.SET_INPUT_VALUE, parameters, client_message_id });
    }

    //{"action": "remove_input_value", "parameters": {"input_name": "prompt"}}

    const removeInputValue = ({ input_name, client_message_id }) => {
        const parameters = {
            input_name
        };
        sendMessage({ action: MessageType.REMOVE_INPUT_VALUE, parameters, client_message_id });
    }

    const editFlowNameAndDescription = async ({ name, description }) => {
        setFlow((prev) => {
            const newFlow = { ...prev };
            newFlow.name = name;
            newFlow.description = description;
            return newFlow;
        });
        if (name !== flow.name) {
            await postFlowsName(flow.id, name);
        }
        if (description !== flow.description) {
            await postFlowsDescription(flow.id, description);
        }

        const newState = {
            ...location.state,
            flow: { ...location.state.flow, name, description }
        }
        navigate(`/flow/${flow.id}`, { state: newState });
    }


    if (!flow) {
        return <LoaderWithText text="Loading flow..." />;
    }

    return (
        <WorkflowContext.Provider value={{
            id,
            flow,
            bodyCanva,
            setBodyCanva,
            selectedNode,
            contextMenu,
            setContextMenu,
            addStep,
            repositionStep,
            removeStep,
            renameStep,
            sendSync,
            addLink,
            removeLink,
            getAvailableMappings,
            getAvailableInputsMappings,
            setDataValue,
            setMappingValue,
            lockStep,
            unlockStep,
            setGlobalInput,
            setGlobalOutput,
            runFlow,
            globalInputs,
            globalOutputs,
            setStepShowOutput,
            removeGlobalInput,
            removeGlobalOutput,
            runningFlow,
            removeDataValue,
            getCanvaInputs,
            getCanvaOutputs,
            getAvailableInputs,
            getAvailableOutputs,
            editFlowNameAndDescription,
            setInputValue,
            removeInputValue
        }}>
            {loading && <LoaderWithText text="Loading flow..." />}
            {children}
        </WorkflowContext.Provider>
    );

}

export const useWorkflow = () => {
    return useContext(WorkflowContext);
}