// components/ContextMenu.js
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Dialog, IconButton, Typography } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { ModalCancelButton, ModalConfirmButton } from 'components/CustomButtons';
import TextInput from 'components/inputs/TextInput';
import { Menu } from '@mui/material';
import { Box } from '@mui/material';
import { PopMenuButton } from 'components/CustomButtons';
import { EditOutlined, DeleteOutline } from '@mui/icons-material';
import { timbalGrey } from 'components/CustomColors';
import { useWorkflow } from '../WorkflowContext';

const ContextMenu = () => {
    const { contextMenu, setContextMenu, removeStep, renameStep, removeLink } = useWorkflow();

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [stepName, setStepName] = useState('');

    useEffect(() => {
        if (contextMenu !== null && contextMenu.item.type.toLowerCase().includes('node')) {
            setStepName(contextMenu.item.data.name);
        }
    }, [contextMenu]);

    const handleDelete = () => {
        if (contextMenu.item.type.toLowerCase().includes('edge')) {
            removeLink({ link_id: contextMenu.item.id });
        } else {
            removeStep({ step_id: contextMenu.item.id });
        }
        setContextMenu(null);
    }

    const handleEditMenu = () => {
        renameStep({ step_id: contextMenu.item.id, name: stepName });
        handleCloseEditDialog();
    }

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setContextMenu(null);
    }

    if (contextMenu === null) {
        return null;
    }

    const isEdge = contextMenu.item.type.toLowerCase().includes('edge');

    return (
        <>
            <Menu
                keepMounted
                open={contextMenu !== null}
                onClose={() => setContextMenu(null)}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
                aria-labelledby='card-button'
                sx={{
                    '& .MuiPaper-root': {
                        boxShadow: 'none',
                        border: `1px solid ${timbalGrey[300]}`,
                    }
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', ml: 1, mr: 1 }}>
                    {!isEdge && (
                        <PopMenuButton onClick={() => setOpenEditDialog(true)} startIcon={<EditOutlined sx={{ width: 25, height: 25 }} />}>
                            <Typography variant="body2" sx={{ fontWeight: 700 }}>
                                Rename
                            </Typography>
                            <Box flexGrow={1} />
                        </PopMenuButton>
                    )}
                    <PopMenuButton onClick={handleDelete} startIcon={< DeleteOutline sx={{ width: 25, height: 25 }} />}>
                        <Typography variant="body2" sx={{ fontWeight: 700 }}>
                            Delete
                        </Typography>
                        <Box flexGrow={1} />
                    </PopMenuButton>
                </Box>
            </Menu>
            {!isEdge && (
                <Dialog
                    open={openEditDialog}
                    onClose={handleCloseEditDialog}
                    onClick={(e) => e.stopPropagation()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        borderRadius: '6px',
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            backdropFilter: 'blur(2px)',
                        }
                    }}
                    fullWidth
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ p: 1, pb: 0 }}>
                            <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseEditDialog}>
                                <CancelOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>Rename step</Typography>
                        <TextInput label='Name' initialValue={stepName} placeholder={'Step name'} title={'Step name'} onChange={(e) => setStepName(e.target.value)} multiline={false} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <ModalCancelButton onClick={handleCloseEditDialog} >
                                    <Typography variant="body2">
                                        Cancel
                                    </Typography>
                                </ModalCancelButton>
                                <ModalConfirmButton onClick={handleEditMenu} >
                                    <Typography variant="body2" >
                                        Save
                                    </Typography>
                                </ModalConfirmButton>
                            </Box>
                        </Box>
                    </Box>
                </Dialog >
            )}
        </>
    );
};

export default ContextMenu;
