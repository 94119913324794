import React, { useMemo, useState } from 'react';
import { ConnectableInput } from 'canva/components/ConnectableInputs';
import BoolInput from 'components/inputs/BoolInput';
import SliderInput from 'components/inputs/SliderInput';
import NumberInput from 'components/inputs/NumberInput';
import TextInput from 'components/inputs/TextInput';
import FileInput from 'components/inputs/FileInput';
import DropdownInput from 'components/inputs/DropdownInput';
import { Divider } from '@mui/material';
import { useWorkflow } from 'canva/workflow/WorkflowContext';
import GlobalInput from './GlobalInput';
import { uploadFile } from 'utils/uploadersTimbal';
import { useApi } from 'api/ApiContext';
import { useSnackbar } from 'notistack';
import { debounce } from '../utils/debounce';
import FileInputMulti from 'components/inputs/FileInputMulti';

const StepInput = ({ input, global = false }) => {

    const { selectedNode, setDataValue, removeDataValue, setInputValue, removeInputValue } = useWorkflow();
    const { postUploadsPresignPut } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const [uploadProgress, setUploadProgress] = useState(-1);

    // Use useMemo to debounce the function to avoid unnecessary re-creations
    const handleOnChange = debounce(async (e) => {
        try {
            let value = e.target.value;
            if (e && e.target && e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                setUploadProgress(0);
                const resp = await postUploadsPresignPut(file.size, file.type, file.name);
                if (resp.success) {
                    try {
                        const contentUrl = await uploadFile(resp.success, file, (progress) => {
                            setUploadProgress(progress);
                        });
                        setUploadProgress(-1);
                        enqueueSnackbar('File uploaded successfully', { variant: 'success' });
                        value = contentUrl;
                    } catch (error) {
                        setUploadProgress(-1);
                        enqueueSnackbar('Error uploading file', { variant: 'error' });
                    }
                }
            }

            if (input.value.realType === 'string' && value.length === 0) {
                value = null;
            } else if (input.value.realType === 'integer' || input.value.realType === 'number') {
                if (value) {
                    value = input.value.realType === 'integer' ? parseInt(value) : parseFloat(value);
                }
            } else if (input.value.realType === 'boolean') {
                value = e.target.checked;
            }

            if (global) {
                if (value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
                    removeInputValue({ input_name: input.key, client_message_id: 'set_data' });
                } else {
                    setInputValue({ input_name: input.key, value, client_message_id: 'set_data' });
                }
            } else {
                if (value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
                    removeDataValue({ step_id: selectedNode.id, step_param_name: e.target.name, client_message_id: 'set_data' });
                } else {
                    setDataValue({ step_id: selectedNode.id, step_param_name: e.target.name, value, client_message_id: 'set_data' });
                }
            }
        } catch (error) {
            console.error(error, e);
        }
    }, 337);// Re-debounce only when these dependencies change

    useMemo(() => {

        if (input.value.type === 'array') {
            input.value = { ...input.value, ...input.value.items };
            input.value.isArray = true;
        }

        input.value.realType = input.value.type;

        if (input.value.choices && input.value.choices.length > 0) {
            input.value.type = 'dropdown';
        }
        if (input.value.format === 'uri') {
            input.value.type = 'file';
        }
        if (input.value.type === 'number') {
            input.value.step = 0.01;
        }
        if (input.value.minimum && input.value.maximum) {
            input.value.type = 'slider';
        }
        if (input.value.default) {
            input.value.initialValue = input.value.default;
            delete input.value.default;
        }

        if (selectedNode) {
            if (selectedNode.data.inputs_data && selectedNode.data.inputs_data[input.key]) {
                if (selectedNode.data.inputs_data[input.key].type === 'map') {
                    input.value.initialValue = selectedNode.data.inputs_data[input.key];
                } else {
                    input.value.initialValue = selectedNode.data.inputs_data[input.key].value;
                }
            }
        }

    }, [input, selectedNode]);

    if (global) {
        switch (input.value.type) {
            case 'boolean':
                return <GlobalInput sx={{ mt: 0 }}><BoolInput {...input.value} name={input.key} onChange={handleOnChange} /></GlobalInput>
            case 'integer':
            case 'number':
            case 'slider':
                return <GlobalInput><NumberInput {...input.value} name={input.key} onChange={handleOnChange} /></GlobalInput>
            case 'string':
                return <GlobalInput><TextInput {...input.value} name={input.key} onChange={handleOnChange} /></GlobalInput>
            case 'dropdown':
                return <GlobalInput><DropdownInput {...input.value} name={input.key} onChange={handleOnChange} resetable={true} /></GlobalInput>
            case 'file':
                return !input.value.isArray ?
                    <GlobalInput sx={{ alignContent: 'end', mb: 0.5 }}><FileInput  {...input.value} name={input.key} onChange={handleOnChange} tip={uploadProgress >= 0 ? `Uploading ${uploadProgress}%` : null} /></GlobalInput>
                    : <GlobalInput sx={{ alignContent: 'start', mt: 4 }}><FileInputMulti  {...input.value} name={input.key} onChange={handleOnChange} /></GlobalInput>
            case 'divider':
                return <Divider />;
            default:
                return null
        }
    } else {
        switch (input.value.type) {
            case 'boolean':
                return <ConnectableInput sx={{ mt: 0 }}><BoolInput {...input.value} name={input.key} onChange={handleOnChange} /></ConnectableInput>
            case 'integer':
            case 'number':
                return <ConnectableInput><NumberInput {...input.value} name={input.key} onChange={handleOnChange} /></ConnectableInput>
            case 'slider':
                return <ConnectableInput><SliderInput {...input.value} name={input.key} onChange={handleOnChange} /></ConnectableInput>
            case 'string':
                return <ConnectableInput><TextInput {...input.value} name={input.key} onChange={handleOnChange} /></ConnectableInput>
            case 'dropdown':
                return <ConnectableInput><DropdownInput {...input.value} name={input.key} onChange={handleOnChange} resetable={true} /></ConnectableInput>
            case 'file':
                return !input.value.isArray ?
                    <ConnectableInput sx={{ alignContent: 'end', mb: 0.5 }}><FileInput  {...input.value} name={input.key} onChange={handleOnChange} tip={uploadProgress >= 0 ? `Uploading ${uploadProgress}%` : null} /></ConnectableInput>
                    : <ConnectableInput sx={{ alignContent: 'start', mt: 4 }}><FileInputMulti  {...input.value} name={input.key} onChange={handleOnChange} /></ConnectableInput>
            case 'divider':
                return <Divider />;
            default:
                return null
        }
    }


}

export default StepInput;