const uploadFile = async (resp, file, onProgress) => {
    if (resp) {
        const { uploadMethod, uploadUri, uploadHeaders, contentUrl } = resp;
        if (uploadMethod === 'PUT') {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', uploadUri);
                // Set headers
                Object.keys(uploadHeaders).forEach((key) => {
                    if (key !== 'content-length') {
                        xhr.setRequestHeader(key, uploadHeaders[key]);
                    }
                });
                // Track progress
                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable && onProgress) {
                        const percentComplete = Math.round((event.loaded / event.total) * 100);
                        onProgress(percentComplete);
                    }
                };
                // Handle completion
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(contentUrl);
                    } else {
                        reject(new Error(`Upload failed with status ${xhr.status} ${xhr.statusText}`));
                    }
                };
                // Handle errors
                xhr.onerror = () => {
                    reject(new Error('Upload failed due to a network error'));
                };
                xhr.send(file);
            });
        }
    }
};

export { uploadFile };
