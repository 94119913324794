// states.js

import { AccessTimeOutlined, CheckCircleOutlineRounded, ErrorOutlineRounded, WarningAmberRounded } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";


// Define the Status class
class Status {
    constructor(icon, color, animated = false) {
        this.icon = icon;
        this.color = color;
        this.animated = animated;
    }
}

// Instantiate the status objects
export const errorStatus = new Status(
    <ErrorOutlineRounded sx={{ color: '#f44336', fontSize: 20 }} />, // Red for error
    '#f44336'
);

export const runningStatus = new Status(
    <CircularProgress size={16} sx={{ color: '#00aaff' }} />, // Blue for running
    '#00aaff',
    true
);

export const successStatus = new Status(
    <CheckCircleOutlineRounded sx={{ color: '#4caf50', fontSize: 20 }} />, // Green for success
    '#4caf50'
);

export const warningStatus = new Status(
    <WarningAmberRounded sx={{ color: '#ff9800', fontSize: 20 }} />, // Orange for warning
    '#ff9800'
);

export const defaultStatus = new Status(
    <AccessTimeOutlined sx={{ color: '#000', fontSize: 20 }} />, // Black for default
    '#000'
);
// Export as an object for easier import and use
export const statusMap = {
    error: errorStatus,
    failed: errorStatus,
    running: runningStatus,
    building: runningStatus,
    success: successStatus,
    ready: successStatus,
    warning: warningStatus,
    none: defaultStatus
};
