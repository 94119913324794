import { useContext, createContext } from "react";
import { useAuth } from "auth/AuthContext";
import { DEBUG } from "utils/miscelanea";
import { useSnackbar } from "notistack";

const ApiContext = createContext();

export const ApiContextProvider = ({ children }) => {

    const { refreshToken } = useAuth();
    const baseApiUrl = 'https://monolith.timbal.ai'
    const { enqueueSnackbar } = useSnackbar();

    const timbalFetch = async (url, options = {}, retry = true) => {
        // Add Authorization header
        options.headers = {
            ...options.headers,
            'x-auth-token': localStorage.getItem('x-auth-token')
        };
        // Add dev flag if dev key in local storage
        if (localStorage.getItem('x-dev-key')) {
            options.headers['x-dev-key'] = localStorage.getItem('x-dev-key');
        }
        try {
            const response = await fetch(baseApiUrl + url, options);
            if (response.status === 401 && retry) {
                // Attempt to refresh the token
                await refreshToken();
                // Retry the original request
                return timbalFetch(url, options, retry = false);
            }
            if (response.status === 400) {
                const errorData = await response.text();
                return { error: errorData };
            }
            if (response.status === 422) {
                const errorData = await response.text();
                enqueueSnackbar(errorData.message || 'Unexpected error occurred', { variant: 'warning' });
                return { error: errorData };
            }
            if (!response.ok) {
                console.error('Refresh error:', response);
                return null;
            }
            if (response.status === 204) {
                if (DEBUG) {
                    console.log('Fetch: ' + url, { success: 'OK' });
                }
                return { success: 'OK' };
            }
            else {
                const data = await response.json();
                if (DEBUG) {
                    console.log('Fetch: ' + url, { success: data });
                }
                return { success: data };
            }
        } catch (error) {
            console.error('Fetch error:', error);
            return null;
        }
    };

    const getSession = async () => {
        const response = await timbalFetch('/session');
        if (response) {
            return response;
        }
    };

    const getIntegrations = async () => {
        const response = await timbalFetch('/integrations');
        if (response) {
            return response;
        }
    }

    // POST /integrations/revoke
    // @body {string} integrationId
    // -> 204
    const postRevokeIntegration = async (integrationId) => {
        const response = await timbalFetch('/integrations/revoke', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ integrationId })
        });
        if (response) {
            return response;
        }
    }

    //GET GET /integrations/auth_url?integrationId=xxx
    // -> 200
    const getAuthIntegrationUrl = async (integrationId) => {
        const response = await timbalFetch(`/integrations/auth_url?integrationId=${integrationId}`);
        if (response) {
            return response;
        }
    }

    // POST /flows
    // @param {string?} name
    // (podem afegir mes coses depenent de la ux que volgueu fer)

    // -> 200 { flowId: string }

    const postFlows = async (name) => {
        const response = await timbalFetch('/flows', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name })
        });
        if (response) {
            return response;
        }
    }

    // POST /flows/name
    // @param {string} flowId
    // @param {string} name

    // -> 204

    const postFlowsName = async (flowId, name) => {
        const response = await timbalFetch(`/flows/name`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, flowId })
        });
        if (response) {
            return response;
        }
    }

    // POST /flows/description
    // @param {string} flowId
    // @param {string} description

    // -> 204

    const postFlowsDescription = async (flowId, description) => {
        const response = await timbalFetch(`/flows/description`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ description, flowId })
        });
        if (response) {
            return response;
        }
    }

    // GET /flows/my_flows
    // @param {string?} pageToken

    // -> 200 { flows: [Flow], nextPageToken: string? }

    // Flow: { id: string, name: string, description: string, isPublic: bool, createdAt: epoch, updatedAt: epoch }

    const getMyFlows = async (pageToken) => {
        const query = pageToken ? `?pageToken=${pageToken}` : '';
        const response = await timbalFetch(`/flows/my_flows${query}`);
        if (response) {
            return response;
        }
    }

    //GET /flows
    // @param {string?} flowId

    // -> 200 { flow: Flow }

    const getFlow = async (flowId) => {
        const response = await timbalFetch(`/flows?flowId=${flowId}`);
        if (response) {
            return response;
        }
    }

    //     GET /flows/explore
    // @param {string?} pageToken

    // -> 200 { flows: [FlowVersion], nextPageToken: string? }

    // FlowVersion:
    // { id: string, name: string, description: string, isPublic: bool, createdAt: epoch, updatedAt: epoch, versionId: string }

    const getExploreFlows = async ({ pageToken = null, searchQuery = null } = {}) => {
        let query = pageToken ? `?pageToken=${pageToken}` : '';
        if (searchQuery) {
            query += query ? `&searchQuery=${searchQuery}` : `?searchQuery=${searchQuery}`;
        }
        const response = await timbalFetch(`/flows/explore${query}`);
        if (response) {
            return response;
        }
    }

    //     GET /steps/explore
    // @param {string?} searchQuery
    // @param {array<string>?} tagsIds
    // @param {string?} pageToken

    // -> 200 {stepsVersions: array<StepVersion>, nextPageToken: string?}

    const getExploreSteps = async ({ searchQuery = null, tagsIds = null, pageToken = null } = {}) => {
        let query = pageToken ? `?pageToken=${pageToken}` : '';
        if (searchQuery) {
            query += query ? `&searchQuery=${searchQuery}` : `?searchQuery=${searchQuery}`;
        }
        if (tagsIds) {
            query += query ? `&tagsIds=${tagsIds.join(',')}` : `?tagsIds=${tagsIds.join(',')}`;
        }
        const response = await timbalFetch(`/steps/explore${query}`);
        if (response) {
            return response;
        }
    }

    //     GET /steps/tags

    // -> 200 { tags: Vec<Tag> }

    const getStepsTags = async () => {
        const response = await timbalFetch(`/steps/tags`);
        if (response) {
            return response;
        }
    }

    //     POST /flows/delete
    // @param {string} flowId

    // -> 204

    const postFlowsDelete = async (flowId) => {
        const response = await timbalFetch(`/flows/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ flowId })
        });
        if (response) {
            return response;
        }
    }

    // POST /users/api_tokens
    // @body {string} name

    // -> 200 { apiToken:  }

    const postUsersApiTokens = async (name) => {
        const response = await timbalFetch('/users/api_tokens', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name })
        });
        if (response) {
            return response;
        }
    }


    // DELETE /users/api_tokens
    // @body {string} apiTokenId

    // -> 204

    const deleteUsersApiTokens = async (apiTokenId) => {
        const response = await timbalFetch('/users/api_tokens', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ apiTokenId })
        });
        if (response) {
            return response;
        }
    }


    // GET /users/api_tokens

    // -> 200: {
    //     "api_tokens": [
    //         {
    //             "id": 2,
    //             "name": "Test",
    //             "hiddenToken": "t1_i94***********",
    //             "createdAt": 1727216534000
    //         }
    //     ]
    // }

    const getUsersApiTokens = async () => {
        const response = await timbalFetch('/users/api_tokens');
        if (response) {
            return response;
        }
    }

    // POST /flows/versions
    // @param {string} flowId
    // @param {string} name

    // -> 204

    const postFlowsVersions = async (flowId, name) => {
        const response = await timbalFetch('/flows/versions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ flowId, name })
        });
        if (response) {
            return response;
        }
    }

    // GET /runs
    // @param {string?} flowId
    // @param {string?} status
    // @param {string?} source
    // @param {epoch?} createdAfter
    // @param {epoch?} createdBefore
    // @param {string?} pageToken

    // -> 200 { runs: Vec<Run>, nextPageToken: string? }

    const getRuns = async ({ flowId = null, status = null, source = null, createdAfter = null, createdBefore = null, pageToken = null } = {}) => {
        let query = pageToken ? `?pageToken=${pageToken}` : '';
        if (flowId) {
            query += query ? `&flowId=${flowId}` : `?flowId=${flowId}`;
        }
        if (status) {
            query += query ? `&status=${status}` : `?status=${status}`;
        }
        if (source) {
            query += query ? `&source=${source}` : `?source=${source}`;
        }
        if (createdAfter) {
            query += query ? `&createdAfter=${createdAfter}` : `?createdAfter=${createdAfter}`;
        }
        if (createdBefore) {
            query += query ? `&createdBefore=${createdBefore}` : `?createdBefore=${createdBefore}`;
        }
        const response = await timbalFetch(`/runs${query}`);
        if (response) {
            return response;
        }
    }

    // GET /flows/versions
    // @param {string} flowId
    // @param {string?} pageToken

    // -> 200 { nextPageToken: string?, flow_versions: FlowVersion }

    const getFlowsVersions = async (flowId, pageToken) => {
        const query = pageToken ? `&pageToken=${pageToken}` : '';
        const response = await timbalFetch(`/flows/versions?flowId=${flowId}${query}`);
        if (response) {
            return response;
        }
    }

    // GET /flows/my_flows_names
    // -> 200 { flowsNames: FlowName}

    const getFlowsNames = async () => {
        const response = await timbalFetch('/flows/my_flows_names');
        if (response) {
            return response;
        }
    }

    // POST /uploads/presign_put
    // @body {int} contentLength
    // @body {string} contentType
    // @body {string} extension // e.g. “.jpg” amb el punt!

    // -> 200 { upload_method: str, upload_uri: str, upload_headers: map<str, str>, content_url: str }

    /**
     * Post a request to get a presigned URL to upload a file to S3
     * @param {int} contentLength The length of the file in bytes
     * @param {string} contentType The content type of the file
     * @param {string} extension The extension of the file
     * @returns {object} The response object
     * @returns {string} response.upload_method The method to use for the upload
     * @returns {string} response.upload_uri The URI to upload the file to
     * @returns {object} response.upload_headers The headers to use for the upload
     * @returns {string} response.content_url The URL to access the uploaded file
     * @returns {null} null If the request fails
     */

    const postUploadsPresignPut = async (contentLength, contentType, fileName) => {
        const response = await timbalFetch('/uploads/presign_put', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contentLength, contentType, fileName })
        });
        if (response) {
            return response;
        }
    }

    // GET /runs/canvas
    // @param {string} flowId
    // @param {string?} pageToken

    // -> 200 {canvasRuns: CanvasRun, nextPageToken: string?}

    const getRunsCanvas = async (flowId, pageToken) => {
        const query = pageToken ? `&pageToken=${pageToken}` : '';
        const response = await timbalFetch(`/runs/canvas?flowId=${flowId}${query}`);
        if (response) {
            return response;
        }
    }

    return (
        <ApiContext.Provider
            value={{
                getSession,
                getIntegrations,
                postRevokeIntegration,
                getAuthIntegrationUrl,
                postFlows,
                postFlowsName,
                postFlowsDescription,
                getMyFlows,
                getFlow,
                getExploreFlows,
                postFlowsDelete,
                postUploadsPresignPut,
                getExploreSteps,
                getStepsTags,
                getRunsCanvas,
                postUsersApiTokens,
                deleteUsersApiTokens,
                getUsersApiTokens,
                postFlowsVersions,
                getFlowsVersions,
                getRuns,
                getFlowsNames
            }}>
            {children}
        </ApiContext.Provider>
    );

};

export const useApi = () => {
    return useContext(ApiContext);
}