import React, { useState, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import { FileUploadRounded, DeleteOutline } from '@mui/icons-material';
import CustomTextField from './components/CustomTextField';
import { timbalGrey } from 'components/CustomColors';
import InputHeader from './components/InputHeader';
import { Typography } from '@mui/material';
import mime from 'mime';
import ReactPlayer from 'react-player';


const FileInput = (props) => {
    const [fullValue, setFullValue] = useState(props.initialValue);
    const [value, setValue] = useState(props.initialValue?.split('/').slice(-1) ?? null);
    const [file, setFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const [error, setError] = useState(false);

    const handleTextInputChange = (e) => {
        setError(false);
        setValue(e.target.value);
        setFullValue(e.target.value);
        setFile(null);
        if (e.target.value && !new RegExp('^(http|https)://').test(e.target.value)) {
            setError(true);
            return;
        }
        if (props.onChange && !error) {
            props.onChange(e);
        }
    };

    const handleFileInputChange = (e) => {
        setError(false);
        if (e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);
            setValue(selectedFile.name);
            resultImage(selectedFile);
            if (props.onChange && !error) {
                props.onChange(e);
            }
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleRemoveFile = () => {
        setError(false);
        setValue('');
        setFullValue(null);
        setFile(null);
        if (props.onChange && !error) {
            const event = {
                target: {
                    name: props.name,
                    value: '',
                    files: null,
                },
            };
            props.onChange(event);
        }
    };

    const resultImage = (file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            setFullValue(e.target.result);
        };
        reader.readAsDataURL(file);
    };


    const handleDrop = (e) => {
        setError(false);
        e.preventDefault();
        setIsDragging(false);
        if (e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0];
            setFile(droppedFile);
            setValue(droppedFile.name);
            resultImage(droppedFile);
            if (props.onChange) {
                const event = {
                    target: {
                        name: props.name,
                        value: droppedFile.name,
                        files: e.dataTransfer.files,
                    },
                };
                props.onChange(event);
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    return (
        <Box sx={{ display: "grid" }} gap={1}>
            {props.hideHeader ? null : <InputHeader title={props.title} description={props.description} boundaries={props.tip} name={props.name} />}
            <PreviewFile url={fullValue} />
            <Box
                sx={{ display: "flex", alignItems: "center" }}
                gap={1} onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >
                <CustomTextField
                    placeholder={isDragging ? 'Drop file here' : 'Enter a URL, or upload a file'}
                    value={value}
                    size="small"
                    fullWidth
                    name={props.name}
                    disabled={props.disabled}
                    onChange={handleTextInputChange}
                    error={error}
                    sx={{
                        '& .MuiInputBase-input': {
                            borderColor: isDragging ? '#5012cb' : error ? 'red' : timbalGrey[300],
                            border: isDragging ? '2px dashed #5012cb' : '',
                            '&:focus': {
                                borderColor: error ? 'red' : '#5012cb !important',
                            },
                        },
                    }}
                />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    name={props.name}
                    disabled={props.disabled}
                    onChange={handleFileInputChange}
                />
                {!file && !value ? (
                    <IconButton sx={{ p: 0.5 }} onClick={handleUploadClick}>
                        <FileUploadRounded style={{ fontSize: 24 }} />
                    </IconButton>
                ) : (
                    <IconButton sx={{ p: 0.5 }} onClick={handleRemoveFile}>
                        <DeleteOutline style={{ fontSize: 24 }} />
                    </IconButton>
                )}
            </Box>
            {error && (
                <Box>
                    <Typography variant="caption" color="error" >{'Input value is not a valid URL.'}</Typography>
                </Box>
            )}
        </Box>
    );
}

const PreviewFile = ({ url }) => {
    if (!url) {
        return null;
    }

    let mimeType = '';
    if (url.includes('data:')) {
        mimeType = url.split(';')[0].split(':')[1];
    } else {
        mimeType = mime.getType(url) ?? '';
    }

    if (mimeType.includes('image')) {
        return (
            <Box
                sx={{
                    borderRadius: '6px',
                    width: '100%',
                    paddingTop: '100%', // This creates a square container
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: 'black', // Add black background
                }}
            >
                <img
                    src={url}
                    alt="preview"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain', // This maintains aspect ratio
                    }}
                />
            </Box>
        );
    } else if (mimeType.includes('audio')) {
        return <Box sx={{ display: 'flex', overflowY: 'hidden' }}>
            <ReactPlayer url={url} controls={true} height={40} />
        </Box>
    } else if (mimeType.includes('video')) {
        return <Box sx={{ display: 'flex', overflowY: 'hidden' }}>
            <ReactPlayer url={url} controls={true} />
        </Box>
    } else {
        return null;
    }
}

export default FileInput;
