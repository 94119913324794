import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { timbalGrey } from 'components/CustomColors';
import InputsTooltip from './InputsTooltip';
import { statusMap } from 'canva/utils/states';
import { useWorkflow } from 'canva/workflow/WorkflowContext';
import { useDataMissing } from 'canva/workflow/DataMissingContext';


const InputHeaderWithStatus = ({ title, description, boundaries, name }) => {

    const [status, setStatus] = useState(null);

    const { selectedNode } = useWorkflow();
    const { dataMissing } = useDataMissing();

    useEffect(() => {
        if (selectedNode && dataMissing) {
            if (dataMissing[selectedNode.id] && dataMissing[selectedNode.id].includes(name)) {
                setStatus('warning');
            } else {
                setStatus(null);
            }
        }
    }, [selectedNode, dataMissing, name]);


    return (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.25 }}>
                {status &&
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {statusMap[status].icon}
                    </Box>
                }
                <Typography variant="body2" sx={{ fontWeight: 500 }}>{title}</Typography>
            </Box>
            {description ? <InputsTooltip title={description} placement="top">
                <InfoOutlinedIcon style={{ color: timbalGrey[700], fontSize: 16 }} />
            </InputsTooltip> : null}

            <Box flexGrow={1} />
            {boundaries && <Typography sx={{ color: timbalGrey[700], fontSize: 12, maxWidth: 200 }} noWrap>{boundaries}</Typography>}
        </Box>);
}

const InputHeaderSimple = ({ title, description, boundaries, tipChild }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>{title}</Typography>
            {description ? <InputsTooltip title={description} placement="top">
                <InfoOutlinedIcon style={{ color: timbalGrey[700], fontSize: 16 }} />
            </InputsTooltip> : null}
            <Box flexGrow={1} />
            {tipChild !== null ? tipChild :
                boundaries && <Typography sx={{ color: timbalGrey[700], fontSize: 12, maxWidth: 200 }} noWrap>{boundaries}</Typography>}
        </Box>
    );
}

const InputHeader = ({ title, description, boundaries, name, tipChild }) => {

    if (name) {
        return <InputHeaderWithStatus title={title} description={description} boundaries={boundaries} name={name} />
    } else {
        return <InputHeaderSimple title={title} description={description} boundaries={boundaries} tipChild={tipChild} />
    }
}

export default InputHeader;