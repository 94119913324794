import { Box, Typography, Avatar, Link } from "@mui/material";
import { timbalGrey } from "components/CustomColors";



export const TagElement = ({ tag, onClick }) => {
    return (<Box
        onClick={onClick}
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 0.5,
            px: 1,
            py: 0.25,
            backgroundColor: timbalGrey[100],
            border: `1px solid ${timbalGrey[300]}`,
            borderRadius: '4px',
            ...tag.style
        }}>
        <Link href={tag.link} target="_blank" sx={{ textDecoration: tag.link ? null : 'none', color: 'inherit', display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {tag.icon_url && <Avatar variant="square" src={tag.icon_url} sx={{ height: 15, width: 15 }} />}
            <Typography variant="caption" sx={{ fontSize: 12, lineHeight: 1.1 }}>{tag.name}</Typography>
        </Link>
    </Box>)
};

export default TagElement;