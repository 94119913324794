import { Box, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useWorkflow } from 'canva/workflow/WorkflowContext';
import { Dialog } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { ModalConfirmButton } from 'components/CustomButtons';
import { ModalCancelButton } from 'components/CustomButtons';
import { useState } from 'react';


const GlobalInput = ({ children, sx }) => {

    const { removeGlobalInput } = useWorkflow();

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleRemoveGlobalInput = () => {
        removeGlobalInput({
            input_name: children.props.name,
            client_message_id: 'set_inputs_outputs'
        });
    }

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ flexGrow: 1, alignContent: 'center' }}>
                    {children}
                </Box>
                <Box sx={{ display: 'block', alignContent: 'center', mt: 3, ...sx, }}>
                    <IconButton sx={{ p: 0.5 }} onClick={() => setOpenDeleteDialog(true)}>
                        <CloseOutlined />
                    </IconButton>
                </Box>
            </Box >
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onClick={(e) => e.stopPropagation()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    borderRadius: '6px',
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        backdropFilter: 'blur(2px)',
                    }
                }}
                fullWidth
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ p: 1, pb: 0 }}>
                        <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseDeleteDialog}>
                            <CancelOutlined />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2 }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>Delete global input</Typography>
                        <Typography variant="body2">
                            Are you sure you want to delete <b>'{children.props.title}'</b>? The input will be permanently removed as global. All connections will be lost.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <ModalCancelButton onClick={handleCloseDeleteDialog} >
                                <Typography variant="body2">
                                    Cancel
                                </Typography>
                            </ModalCancelButton>
                            <ModalConfirmButton onClick={handleRemoveGlobalInput} >
                                <Typography variant="body2">
                                    Delete
                                </Typography>
                            </ModalConfirmButton>
                        </Box>
                    </Box>
                </Box>
            </Dialog ></>)
}

export default GlobalInput;