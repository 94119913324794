import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Box, Typography, Avatar, Divider, Link } from '@mui/material';
import { timbalGrey } from 'components/CustomColors';
import { styled } from '@mui/material/styles';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails as MuiAccordionDetails } from '@mui/material';
import { useWorkflow } from '../WorkflowContext';
import { grey } from '@mui/material/colors';
import { AddOutlined, MoreVert } from '@mui/icons-material';
import StepInput from 'canva/components/StepInputs';
import GlobalInputsModal from 'canva/components/GlobalInputsModal';
import GlobalOutputsModal from 'canva/components/GlobalOutputsModal';
import StepOutput from 'canva/components/StepOutputs';
import { SecondaryButtonCanvas } from 'components/CustomButtons';
import { useRightDrawer } from '../RightDrawerProvider';
import { IconButton } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { CancelOutlined } from '@mui/icons-material';
import TextInput from 'components/inputs/TextInput';
import { ModalCancelButton, ModalConfirmButton } from 'components/CustomButtons';
import { Dialog } from '@mui/material';
import TagElement from './TagElement';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
}));

const RightDrawer = () => {
    const [isClamped, setIsClamped] = useState(true); // Track if the description is clamped
    const { flow, selectedNode, globalInputs, globalOutputs, setContextMenu, editFlowNameAndDescription } = useWorkflow();
    const { drawerWidth, handleMouseDown } = useRightDrawer();

    const [showSeeMore, setShowSeeMore] = useState(false);
    const descriptionRef = useRef(null);
    const lastSelectedNode = useRef(null);
    const lastShowingAdvancedOptions = useRef(false);

    const handleClampDescription = () => {
        setIsClamped(!isClamped);
    }


    useEffect(() => {
        if (descriptionRef.current) {
            const { scrollHeight, clientHeight } = descriptionRef.current;
            setShowSeeMore(scrollHeight > clientHeight);
        }
    }, [drawerWidth, selectedNode]);

    useEffect(() => {
        setTimeout(async () => {
            if (descriptionRef.current) {
                const { scrollHeight, clientHeight } = descriptionRef.current;
                setShowSeeMore(scrollHeight > clientHeight);
            }
        }, 1037);
    }, []);

    const handleContextMenu = (event, selectedNode) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            item: selectedNode,
        });
    };

    const DrawerSelectedNode = () => {

        const [showingAdvancedOptions, setShowingAdvancedOptions] = useState(lastShowingAdvancedOptions.current);
        const [propertiesArray, setPropertiesArray] = useState([]);
        const [advancedProperties, setAdvancedProperties] = useState([]);

        const handleAdvancedOptions = () => {
            lastShowingAdvancedOptions.current = !showingAdvancedOptions;
            setShowingAdvancedOptions(!showingAdvancedOptions);
        }

        useMemo(() => {
            if (lastSelectedNode.current && selectedNode.id !== lastSelectedNode.current.id) {
                lastShowingAdvancedOptions.current = false;
                setShowingAdvancedOptions(false);
            }
            lastSelectedNode.current = selectedNode;
            const { properties, required } = selectedNode.data.inputs_schema;

            if (properties && required) {
                const requiredProperties = required.map((key) => ({
                    key,
                    value: properties[key]
                }));
                const nonRequiredProperties = Object.entries(properties)
                    .filter(([key]) => !required.includes(key))
                    .map(([key, value]) => ({ key, value }));
                setPropertiesArray(requiredProperties);
                setAdvancedProperties(nonRequiredProperties);
            } else {
                setPropertiesArray([]);
                setAdvancedProperties([]);
            }
        }, []);


        return (<>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center', gap: 2, p: 2 }}>
                    <Avatar src={selectedNode.data.icon_url} variant='square' sx={{
                        width: 30, height: 30, borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, justifyItems: 'center' }}>
                        <Typography variant="h6" sx={{ fontWeight: 700, lineHeight: 1.1 }}>{selectedNode.data.name}</Typography>
                        {selectedNode.data.original_name &&
                            selectedNode.data.original_name !== selectedNode.data.name &&
                            <Typography variant="body2" sx={{ color: grey[700], lineHeight: 1.1 }}>{selectedNode.data.original_name}</Typography>}
                    </Box>
                    <Box flexGrow={1} />
                    <IconButton size='small' sx={{ p: 0.5 }} onClick={(event) => handleContextMenu(event, selectedNode)}>
                        <MoreVert />
                    </IconButton>
                </Box>
                {selectedNode.data.tags && selectedNode.data.tags.length > 0 && <Box sx={{ p: 2, pt: 0 }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center' }}>
                        {selectedNode.data.tags.map((tag, index) => (
                            <TagElement key={index} tag={tag} />
                        ))}
                    </Box>
                </Box>}
                {selectedNode.data.description && <Box sx={{ p: 2, pt: 0 }}>
                    <Typography
                        ref={descriptionRef}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: isClamped ? 4 : 'none',
                            WebkitBoxOrient: "vertical",
                        }}
                        variant="body2"
                    >
                        {selectedNode.data.description}
                    </Typography>
                    {showSeeMore && (
                        <Typography variant='caption' sx={{ mt: 0.5, fontWeight: 500 }}>
                            <Link onClick={handleClampDescription} underline='hover' color={'#5012cb'}>
                                {isClamped ? 'see more' : 'see less'}
                            </Link>
                        </Typography>
                    )}
                </Box>}
                <Divider />
            </Box>
            <Box sx={{ p: 2, display: "grid", gap: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>Step inputs</Typography>
            </Box>
            {propertiesArray.length > 0 ? <Box sx={{ p: 2, pb: advancedProperties.length > 0 ? 0 : 2, display: "grid", gap: 2 }}>
                {propertiesArray.map((property, index) => (
                    <StepInput key={index} input={property} />
                ))}
            </Box> : null}

            {/* Advanced Options */}
            {advancedProperties.length > 0 ? <Accordion expanded={showingAdvancedOptions} onChange={handleAdvancedOptions}
                sx={{ boxShadow: 'none', cursor: 'pointer' }}>
                <AccordionSummary sx={{ p: 2, pt: 0, pb: showingAdvancedOptions ? 0 : 2 }}>
                    <Typography variant="body2" sx={{ color: grey[700], textDecoration: 'underline' }}>Advanced Options</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 2, pt: 0, display: "grid", gap: 2 }}>
                    {advancedProperties.map((property, index) => (
                        <StepInput key={index} input={property} />
                    ))}
                </AccordionDetails>
            </Accordion> :
                null}
        </>);
    }

    const DrawerNoNode = () => {

        const [openInputsDialog, setOpenInputsDialog] = useState(false);
        const [openOutputsDialog, setOpenOutputsDialog] = useState(false);

        const [inputsCanva, setInputsCanva] = useState([]);
        const [outputsCanva, setOutputsCanva] = useState([]);

        useMemo(() => {
            if (globalInputs && globalOutputs) {
                let retInputs = [];
                const properties = globalInputs.schema.properties;
                if (properties) {
                    Object.entries(properties).forEach(([key, value]) => {
                        const inputValue = globalInputs.data[key] ? globalInputs.data[key].value : null;
                        retInputs.push({ key: key, value: { ...value, step_id: key, title: value.title, initialValue: inputValue } });
                    });
                }
                setInputsCanva(retInputs);
                let retOutputs = [];
                const outputs = globalOutputs.schema.properties;
                if (outputs) {
                    Object.entries(outputs).forEach(([key, value]) => {
                        let outputValue = '';
                        if (globalOutputs.data[key]) {
                            outputValue = globalOutputs.data[key].value;
                        }
                        retOutputs.push({ key: key, value: { ...value, step_id: key, title: value.title, value: outputValue } });
                    });
                }
                setOutputsCanva(retOutputs);
            }
        }, []);

        const [openEditDialog, setOpenEditDialog] = useState(false);
        const [flowName, setFlowName] = useState(flow.name);
        const [description, setDescription] = useState(flow.description);

        const handleCloseEditDialog = () => {
            setOpenEditDialog(false);
        }

        const handleEditMenu = () => {
            editFlowNameAndDescription({ name: flowName, description: description });
            setOpenEditDialog(false);
        }

        return (
            <>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pt: 1.5, pb: 1.5, pl: 2, pr: 2 }}>
                        <Typography noWrap variant="h6" sx={{ fontWeight: 700 }}>{flow.name}</Typography>
                        <Box flexGrow={1} />
                        <IconButton size='small' sx={{ p: 0.5 }} onClick={(_) => { setOpenEditDialog(true) }}>
                            <EditOutlined />
                        </IconButton>
                    </Box>
                </Box>
                {flow.description && <Box sx={{ p: 2 }}>
                    <Typography
                        ref={descriptionRef}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: isClamped ? 4 : 'none',
                            WebkitBoxOrient: "vertical",
                        }}
                        variant="body2"
                    >
                        {flow.description}
                    </Typography>
                    {showSeeMore && (
                        <Typography variant='caption' sx={{ mt: 0.5, fontWeight: 500 }}>
                            <Link onClick={handleClampDescription} underline='hover' color={'#5012cb'}>
                                {isClamped ? 'see more' : 'see less'}
                            </Link>
                        </Typography>
                    )}
                </Box>}
                <Divider />
                <Box sx={{ p: 2, display: "grid", gap: 2 }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>Flow inputs</Typography>
                        <Box flexGrow={1} />
                        <SecondaryButtonCanvas startIcon={<AddOutlined />} onClick={() => setOpenInputsDialog(true)}>New</SecondaryButtonCanvas>
                    </Box>
                    {inputsCanva.length > 0 ? inputsCanva.map((input, index) => (
                        <StepInput key={index} input={input} global={true} />
                    )) : null}
                </Box>
                <Divider />
                <Box sx={{ p: 2, display: "grid", gap: 2 }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>Selected outputs</Typography>
                        <Box flexGrow={1} />
                        <SecondaryButtonCanvas startIcon={<AddOutlined />} onClick={() => setOpenOutputsDialog(true)}>New</SecondaryButtonCanvas>
                    </Box>
                    {outputsCanva.length > 0 ? outputsCanva.map((output, index) => (
                        <StepOutput key={index} output={output} global={true} />
                    )) : null}
                </Box>
                {/* INPUTS MODAL BELOW */}
                <GlobalInputsModal openInputsDialog={openInputsDialog} setOpenInputsDialog={setOpenInputsDialog} />
                {/* OUTPUTS MODAL BELOW */}
                <GlobalOutputsModal openOutputsDialog={openOutputsDialog} setOpenOutputsDialog={setOpenOutputsDialog} />
                <Dialog
                    open={openEditDialog}
                    onClose={handleCloseEditDialog}
                    onClick={(e) => e.stopPropagation()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        borderRadius: '6px',
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            backdropFilter: 'blur(2px)',
                        }
                    }}
                    fullWidth
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ p: 1, pb: 0 }}>
                            <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseEditDialog}>
                                <CancelOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>Edit flow</Typography>
                        <TextInput label='Name' initialValue={flow.name} placeholder={'Flow name'} title={'Flow name'} onChange={(e) => setFlowName(e.target.value)} multiline={false} />
                        <TextInput label='Description' initialValue={flow.description} placeholder={'Flow description'} title={'Flow description'} onChange={(e) => setDescription(e.target.value)} multiline={true} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <ModalCancelButton onClick={handleCloseEditDialog} >
                                    <Typography variant="body2">
                                        Cancel
                                    </Typography>
                                </ModalCancelButton>
                                <ModalConfirmButton onClick={handleEditMenu} >
                                    <Typography variant="body2" >
                                        Save
                                    </Typography>
                                </ModalConfirmButton>
                            </Box>
                        </Box>
                    </Box>
                </Dialog >
            </>
        )
    }

    return (
        <Box
            sx={{
                width: drawerWidth,
                display: "flex",
                backgroundColor: "white",
                flexDirection: "column",
                height: "calc(100vh - 56px)", // Make the drawer take full height
            }}
        >
            <Box onMouseDown={handleMouseDown} sx={{
                width: "10px",
                cursor: "ew-resize",
                borderLeft: `1px solid ${timbalGrey[300]}`,
                padding: "4px 0 0",
                position: "absolute",
                bottom: 0,
                zIndex: 100,
                height: "calc(100% - 56px)",
                '&:hover': {
                    borderLeft: "2px solid #5012cb"
                }
            }} />
            <Box id="scrollable-drawer" sx={{ flex: 1, overflowY: "auto", overflowX: "hidden", pb: 12 }}> {/* Scrollable area */}
                {selectedNode ? <DrawerSelectedNode /> : <DrawerNoNode />}
            </Box >
        </Box >
    );
};



export default RightDrawer;