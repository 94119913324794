import React, { useState, useRef, useEffect } from 'react';
import { Avatar, Box, IconButton, Link } from '@mui/material';
import { FileUploadRounded, DeleteOutline } from '@mui/icons-material';
import CustomTextField from './components/CustomTextField';
import { timbalGrey } from 'components/CustomColors';
import InputHeader from './components/InputHeader';
import { Typography } from '@mui/material';
import mime from 'mime';
import { useApi } from 'api/ApiContext';
import { uploadFile } from 'utils/uploadersTimbal';
import CircularProgress from '@mui/material/CircularProgress';


const FileInputMulti = (props) => {
    const { postUploadsPresignPut } = useApi();

    const [files, setFiles] = useState(props.initialValue ?? []);
    const [filesPending, setFilesPending] = useState([]);
    const [value, setValue] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const [error, setError] = useState(false);
    const [uploadProgresses, setUploadProgresses] = useState({});

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (props.onChange) {
            const event = { target: { name: props.name, value: files } };
            props.onChange(event);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const uploadFiles = async (filesToUpload) => {
        setFilesPending((filesPending) => [...filesPending, ...filesToUpload]);
        for (let file of filesToUpload) {
            setUploadProgresses({ ...uploadProgresses, [file.name]: 0 });
            const resp = await postUploadsPresignPut(file.size, file.type, file.name);
            if (resp.success) {
                try {
                    const contentUrl = await uploadFile(resp.success, file, (progress) => {
                        setUploadProgresses((prev) => { return { ...prev, [file.name]: progress } });
                    });
                    // remove the file uploadProgress
                    setUploadProgresses((prev) => { return { ...prev, [file.name]: null } });
                    setFilesPending((filesPending) => filesPending.filter(f => f !== file));
                    setFiles((files) => [...files, contentUrl]);
                } catch (error) {
                    // remove the file uploadProgress
                    setUploadProgresses((prev) => { return { ...prev, [file.name]: null } });
                }
            }
        }
    }

    const handleTextInputChange = (e) => {
        setError(false);
        setValue(e.target.value);

        if (!new RegExp('^(http|https)://').test(e.target.value)
            || e.target.value === null
            || e.target.value.length === 0) {
            setError(true);
            return;
        }
        else {
            //push the value to the files array
            setFiles((files) => [...files, e.target.value]);
        }
        setValue('');
    }

    const handleFileInputChange = (e) => {
        setError(false);
        uploadFiles(e.target.files);
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleRemoveFile = (index) => {
        setError(false);
        setFiles((files) => files.filter((file, i) => i !== index));
    };

    const handleDrop = (e) => {
        setError(false);
        e.preventDefault();
        setIsDragging(false);
        uploadFiles(e.dataTransfer.files);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    return (
        <Box sx={{ display: "grid" }} gap={1}>
            {props.hideHeader ? null : <InputHeader title={props.title} description={props.description} boundaries={props.tip} name={props.name} />}
            <Box
                sx={{ display: "flex", alignItems: "center" }}
                gap={1} onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >
                <CustomTextField
                    placeholder={isDragging ? 'Drop file here' : 'Enter a URL, or upload a file'}
                    value={value}
                    size="small"
                    fullWidth
                    name={props.name}
                    disabled={props.disabled}
                    onChange={handleTextInputChange}
                    error={error}
                    sx={{
                        '& .MuiInputBase-input': {
                            borderColor: isDragging ? '#5012cb' : error ? 'red' : timbalGrey[300],
                            border: isDragging ? '2px dashed #5012cb' : '',
                            '&:focus': {
                                borderColor: error ? 'red' : '#5012cb !important',
                            },
                        },
                    }}
                />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    name={props.name}
                    disabled={props.disabled}
                    onChange={handleFileInputChange}
                    multiple
                />
                <IconButton sx={{ p: 0.5 }} onClick={handleUploadClick}>
                    <FileUploadRounded style={{ fontSize: 24 }} />
                </IconButton>
            </Box>
            {error && (
                <Box>
                    <Typography variant="caption" color="error" >{'Input value is not a valid URL.'}</Typography>
                </Box>
            )}
            {(files.length > 0 || filesPending.length > 0) && <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
                {files.map((file, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Link href={file} target="_blank" rel="noopener noreferrer">
                            {(mime.getType(file) ?? '').includes('image') ?
                                <Avatar src={file} variant='square' sx={{ borderRadius: '6px', height: 30, width: 30 }} />
                                : null}
                        </Link>
                        <Typography variant="body2" noWrap sx={{ maxWidth: 250 }}>{file.split('/').slice(-1)}</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={(e) => handleRemoveFile(index)}>
                            <DeleteOutline />
                        </IconButton>
                    </Box>
                ))}
                {filesPending.map((file, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" noWrap sx={{ maxWidth: 275 }}>{file.name}</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <CircularProgress
                            variant={uploadProgresses[file.name] ? 'determinate' : 'indeterminate'}
                            size={20} thickness={3} sx={{ color: uploadProgresses[file.name] ? 'black' : timbalGrey[700] }}
                            value={uploadProgresses[file.name]}
                        />
                    </Box>
                ))}
            </Box>}
        </Box>
    );
}

export default FileInputMulti;
