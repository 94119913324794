import { CloseOutlined, ContentCopyOutlined } from '@mui/icons-material';
import { Typography, Box, IconButton, Grid, Link } from '@mui/material';
import { timbalGrey } from 'components/CustomColors';
import InputHeader from 'components/inputs/components/InputHeader';
import React from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import mime from 'mime';
import { Dialog } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { ModalCancelButton } from 'components/CustomButtons';
import { ModalConfirmButton } from 'components/CustomButtons';
import { useWorkflow } from 'canva/workflow/WorkflowContext';
import ReactPlayer from 'react-player';
import CustomImage from 'components/CustomImage';
import { useRightDrawer } from 'canva/workflow/RightDrawerProvider';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { SecondaryButtonCanvas } from 'components/CustomButtons';

const StepOutput = ({ output, global = false }) => {

    const { enqueueSnackbar } = useSnackbar();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const { removeGlobalOutput } = useWorkflow();
    const { drawerWidth } = useRightDrawer();

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleRemoveGlobalOutput = () => {
        removeGlobalOutput({
            output_name: output.key,
            client_message_id: 'set_inputs_outputs'
        })
    }

    const handleCopyValue = () => {
        if (!output.value.value) {
            enqueueSnackbar('No value to copy! Run the flow first!', { variant: 'error' });
            return;
        }
        navigator.clipboard.writeText(output.value.value).then(() => {
            enqueueSnackbar(`${output.value.title} copied to clipboard!`, { variant: 'success' });
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    }

    const OutputVisualization = ({ item }) => {
        const mimeType = mime.getType(item) ?? '';
        const size = Array.isArray(output.value.value) && output.value.value.length > 1 ? 6 : 12;
        if (mimeType.includes('image')) {
            return <Grid item xs={size} sm={size} md={size} lg={size}>
                <Link href={item} target="_blank" >
                    <CustomImage src={item} alt={output.value.title} sx={{ borderRadius: '6px', width: '100%', aspectRatio: '1/1' }} />
                </Link>
            </Grid>
        }
        else if (mimeType.includes('audio')) {
            return <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ display: 'flex', overflowY: 'hidden' }}>
                    <ReactPlayer url={item} controls={true} height={40} />
                </Box>
            </Grid>
        }
        else if (mimeType.includes('video')) {
            return <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ display: 'flex', overflowY: 'hidden' }}>
                    <ReactPlayer url={item} controls={true} />
                </Box>
            </Grid>
        }
        else {
            return <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ border: `1px solid ${timbalGrey[300]}`, borderRadius: '6px', p: 1, display: 'flex', position: 'relative' }} >
                    {item ? (
                        <Box sx={{ width: '100%', textAlign: 'justify', fontFamily: 'DM Sans', fontSize: 14 }}>
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <Box sx={{ position: 'relative' }}>
                                                <SecondaryButtonCanvas
                                                    sx={{ position: 'absolute', top: 5, right: 5, zIndex: 1000, fontSize: 12, p: 0.25 }}
                                                    onClick={() => handleCopyCode(children)}>
                                                    Copy
                                                </SecondaryButtonCanvas>
                                                <SyntaxHighlighter
                                                    style={dracula}
                                                    language={match[1]}
                                                    wrapLongLines={true}
                                                    customStyle={{
                                                        margin: 0,
                                                        fontSize: 12,
                                                        borderRadius: '6px',
                                                    }}
                                                >
                                                    {String(children).replace(/\n$/, '')}
                                                </SyntaxHighlighter>
                                            </Box>
                                        ) : (
                                            <code className={className} {...props}>
                                                {children}
                                            </code>
                                        )
                                    }
                                }}
                            >
                                {item}
                            </ReactMarkdown>
                        </Box>
                    ) : (
                        <Typography variant="body2" sx={{ color: timbalGrey[700], width: '100%' }}>
                            Run the workflow to see amazing output
                        </Typography>
                    )}
                </Box>
            </Grid>
        }
    }

    const handleCopyCode = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            enqueueSnackbar('Code copied to clipboard!', { variant: 'success' });
        }).catch((err) => {
            console.error('Failed to copy code: ', err);
            enqueueSnackbar('Failed to copy code', { variant: 'error' });
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: global ? drawerWidth - 40 : '100%', width: '100%' }}>
                <Box sx={{ display: 'flex', pr: 0 }}>
                    <InputHeader title={`${output.value.title} ${(output.value.type === 'array' && output.value.value) ? '(' + output.value.value.length + ')' : ''} `} description={output.value.description} />
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton sx={{ p: 0.5 }} onClick={handleCopyValue} >
                        <ContentCopyOutlined sx={{ fontSize: 16 }} />
                    </IconButton>
                    {global && <IconButton sx={{ p: 0.5 }} onClick={() => setOpenDeleteDialog(true)}>
                        <CloseOutlined />
                    </IconButton>}
                </Box>
                <Grid container spacing={1}>
                    {Array.isArray(output.value.value) ?
                        output.value.value.map((item, index) => (
                            <OutputVisualization key={index} item={item} />
                        )) : <OutputVisualization item={output.value.value} />}
                </Grid>
            </Box>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onClick={(e) => e.stopPropagation()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    borderRadius: '6px',
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        backdropFilter: 'blur(2px)',
                    }
                }}
                fullWidth
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ p: 1, pb: 0 }}>
                        <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseDeleteDialog}>
                            <CancelOutlined />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2 }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>Delete global output</Typography>
                        <Typography variant="body2">
                            Are you sure you want to delete <b>'{output.value.title}'</b>? The output will be permanently removed as global.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <ModalCancelButton onClick={handleCloseDeleteDialog} >
                                <Typography variant="body2">
                                    Cancel
                                </Typography>
                            </ModalCancelButton>
                            <ModalConfirmButton onClick={handleRemoveGlobalOutput} >
                                <Typography variant="body2">
                                    Delete
                                </Typography>
                            </ModalConfirmButton>
                        </Box>
                    </Box>
                </Box>
            </Dialog >
        </>

    );
}

export default StepOutput;



