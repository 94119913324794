// src/context/WebSocketContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import WebSocketService from "./WebSocketService";
import { useParams } from "react-router-dom";
import { useApi } from "api/ApiContext";

// Create the WebSocket context
const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {

    const MessageType = {
        ADD_LINK: 'add_link', //done
        ADD_STEP: 'add_step', // done
        GET_AVAILABLE_MAPPINGS: 'get_available_mappings', //done
        GET_AVAILABLE_INPUTS_MAPPINGS: 'get_available_inputs_mappings', //done
        REMOVE_LINK: 'remove_link', //done
        REMOVE_STEP: 'remove_step', // done
        RENAME_STEP: 'rename_step', // done
        RUN: 'run',
        SET_DATA: 'set_data', //done
        SET_INPUT: 'set_input',
        SET_OUTPUT: 'set_output',
        LOCK_STEP: 'lock_step', //done
        UNLOCK_STEP: 'unlock_step', //done
        SET_SHOW_OUTPUT: 'set_show_output', //done
        SAVE: 'save',
        SYNC: 'sync', //done
        REPOSITION_STEP: 'reposition_step',//done
        REMOVE_OUTPUT: 'remove_output', //done
        REMOVE_INPUT: 'remove_input', //done
        REMOVE_DATA: 'remove_data', //done
        GET_DATA: 'get_data', //done
        GET_INPUTS: 'get_inputs', //done
        GET_OUTPUTS: 'get_outputs', //done
        GET_AVAILABLE_INPUTS: 'get_available_inputs', //done
        GET_AVAILABLE_OUTPUTS: 'get_available_outputs', //done
        SET_INPUT_VALUE: 'set_input_value', //done
        REMOVE_INPUT_VALUE: 'remove_input_value', //done
    };

    const MessageCode = {
        FLOW_OUTPUT: 'FLOW_OUTPUT',
        STEP_AVAILABLE_MAPPINGS: 'STEP_AVAILABLE_MAPPINGS',//done
        STEP_OUTPUT: 'STEP_OUTPUT',//done
        STEP_START: 'STEP_START', //done
        STEP_OUTPUT_CHUNK: 'STEP_OUTPUT_CHUNK', //done
        STEP_LOCK: 'STEP_LOCK',
        STEP_UNLOCK: 'STEP_UNLOCK',
        STEP_DATA: 'STEP_DATA',
        SYNC: 'SYNC', //done,
        FLOW_OUTPUTS: 'FLOW_OUTPUTS',
        FLOW_INPUTS: 'FLOW_INPUTS',
        SAVED: 'SAVED',

    }

    const [socketService, setSocketService] = useState(null);
    const { id } = useParams();
    const { getSession } = useApi();

    useEffect(() => {
        // Get the flow id from the URL
        const url = `wss://monolith.timbal.ai/ws?flowId=${id}`;
        const socketInstance = WebSocketService.getInstance(url, getSession);
        setSocketService(socketInstance);

        // Cleanup on unmount
        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
                WebSocketService.instance = null; // Reset the singleton instance
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSession]);

    return (
        <WebSocketContext.Provider value={{ socketService, MessageCode, MessageType }}>
            {children}
        </WebSocketContext.Provider>
    );
};

// Hook to use the WebSocket context
export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
