import { Box, Typography, IconButton, Dialog } from '@mui/material';
import { AccessTimeOutlined, CancelOutlined, ExpandMore, DownloadOutlined } from '@mui/icons-material';
import { useApi } from 'api/ApiContext';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import ReactFlowCanvasPreview from 'home/components/RunsComponents/ReactFlowCanvasPreview';
import ReactJson from '@microlink/react-json-view'
import { timbalGrey } from 'components/CustomColors';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails as MuiAccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import { prettyDate, prettyTime } from 'utils/miscelanea';
import { statusMap } from 'canva/utils/states';
import { TollOutlined } from '@mui/icons-material';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { CircularProgress } from '@mui/material';
import { SecondaryButtonCanvas } from 'components/CustomButtons';
import { useNavigate } from 'react-router-dom';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 1,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
}));

const CanvaRunsModal = ({ openRunsDialog, setOpenRunsDialog, sx }) => {

    const navigate = useNavigate();
    const { getRunsCanvas } = useApi();
    const { id } = useParams();
    const [runs, setRuns] = useState(null);
    const [json, setJson] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setSelectedNode(null);
        setExpandedAccordion(isExpanded ? panel : null);
        setJson(runs[panel.replace('panel', '')].body);
    };

    useEffect(() => {
        if (openRunsDialog) {
            handleOpenRunsDialog();
        } else {
            setJson(null);
            setSelectedNode(null);
            setExpandedAccordion(null);
            setRuns(null);
            setLoading(true);
        }
        // eslint-disable-next-line
    }, [openRunsDialog]);

    const fetchRuns = useCallback(async (pageToken = null) => {
        setIsLoadingMore(true);
        const resp = await getRunsCanvas(id, pageToken);
        if (resp.success) {
            let newRuns = resp.success.canvasRuns;
            newRuns.forEach(run => {
                if (run.reactflow) {
                    run.reactflow.nodes.forEach(node => {
                        if (run.reactflow.data_missing[node.id]) {
                            node.data.status = { code: 'warning', keys: run.reactflow.data_missing[node.id] };
                        }
                        else if (node.data.outputs_data.type === 'error') {
                            node.data.status = { code: 'error', keys: [] };
                        } else {
                            node.data.status = { code: 'success', keys: [] };
                        }
                    });
                }
            });
            setRuns(prevRuns => pageToken ? [...prevRuns, ...newRuns] : newRuns);
            setNextPageToken(resp.success.nextPageToken);
        }
        setIsLoadingMore(false);
    }, [id, getRunsCanvas]);

    const handleOpenRunsDialog = async () => {
        setLoading(true);
        await fetchRuns();
        setLoading(false);
    };

    const handleCloseRunsDialog = () => {
        setOpenRunsDialog(false);
    };

    const handleDownload = (run) => {
        const jsonString = JSON.stringify(run.body, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `run_${run.userRunId}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const handleLoadMore = async () => {
        if (nextPageToken) {
            await fetchRuns(nextPageToken);
        }
    };

    useEffect(() => {
        if (selectedNode) {
            setJson(runs[expandedAccordion.replace('panel', '')].body.steps[selectedNode.id]);
        } else {
            if (expandedAccordion) {
                setJson(runs[expandedAccordion.replace('panel', '')].body);
            } else {
                setJson(null);
            }
        }
    }, [selectedNode, expandedAccordion, runs]);

    return (
        <Dialog
            open={openRunsDialog}
            onClose={handleCloseRunsDialog}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
            maxWidth='xl'
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, pl: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>{"Development Runs History"}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <SecondaryButtonCanvas onClick={() => navigate(`/analytics?flowId=${id}`)}>
                        Production Runs
                    </SecondaryButtonCanvas>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseRunsDialog}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 2, overflowY: 'auto', height: '90vh', maxHeight: '90vh' }}>
                {loading ?
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <CircularProgress size={30} color="inherit" />
                    </Box>
                    : runs === null || runs.length === 0 ?
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>{"No runs yet. Run the flow to see the history."}</Typography>
                        </Box>
                        :
                        <>
                            {runs.map((run, index) => (
                                <Accordion
                                    key={index}
                                    expanded={expandedAccordion === `panel${index}`}
                                    onChange={handleAccordionChange(`panel${index}`)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                        sx={{
                                            boxShadow: 'none',
                                            border: `1px solid ${timbalGrey[300]}`,
                                            elevation: 0,
                                            p: 0,
                                            px: 1,
                                            borderRadius: '6px',
                                            '&:hover': {
                                                backgroundColor: timbalGrey[300],
                                            }
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', width: '100%', pr: 2, pl: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 30, width: 30 }}>
                                                    {statusMap[run.status.toLowerCase()].icon}
                                                </Box>
                                                <Typography sx={{ fontWeight: 700, minWidth: 250 }}>{`Run ${run.userRunId} - ${prettyDate(run.createdAt)}`}</Typography>

                                            </Box>
                                            <Box flexGrow={1} />
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: 0.25,
                                                    }}>
                                                    <AccessTimeOutlined sx={{ height: 20, width: 20 }} />
                                                    <Typography variant="body2">{prettyTime(run.time)}</Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: 0.25,
                                                    }}>
                                                    <TollOutlined sx={{ height: 20, width: 20 }} />
                                                    <Typography variant="body2">{`${run.credits || 0} credits`}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {expandedAccordion === `panel${index}` ?
                                            <Box sx={{ pt: 2, height: '65vh', maxHeight: '65vh' }}>
                                                <PanelGroup direction="horizontal" style={{ height: '100%' }}>
                                                    <Panel defaultSize={50} minSize={10}>
                                                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2, mr: 0.25 }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: 1, minHeight: 25 }}>
                                                                <Typography variant="body2" sx={{ fontWeight: 700 }}>{"Graph"}</Typography>
                                                                {/* <SecondaryButtonCanvas sx={{ height: 25, minHeight: 25 }}>
                                                                    Restore to this run
                                                                </SecondaryButtonCanvas> */}
                                                            </Box>
                                                            {run.reactflow ?
                                                                <ReactFlowCanvasPreview initNodes={run.reactflow.nodes} initEdges={run.reactflow.edges} setSelectedNode={setSelectedNode} />
                                                                :
                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{"No graph available"}</Typography>
                                                                </Box>
                                                            }
                                                        </Box>
                                                    </Panel>
                                                    <PanelResizeHandle>
                                                        <Box sx={{ width: '8px', height: '100%', cursor: 'col-resize', p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Box sx={{ width: 4, height: 50, backgroundColor: timbalGrey[300], borderRadius: '4px', cursor: 'col-resize' }} />
                                                        </Box>
                                                    </PanelResizeHandle>
                                                    <Panel defaultSize={50} minSize={10}>
                                                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2, ml: 0.25 }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: 1, minHeight: 25 }}>
                                                                <Typography variant="body2" sx={{ fontWeight: 700 }}>{`Details`}
                                                                    <span style={{ fontWeight: 400, color: timbalGrey[700] }}>{selectedNode ? ` (${selectedNode.data.name}${selectedNode.data.original_name !== selectedNode.data.name ? ` - ${selectedNode.data.original_name}` : ''})` : ''}</span>
                                                                </Typography>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => handleDownload(run)}
                                                                    sx={{ p: 0, color: 'black', mr: 1 }}
                                                                >
                                                                    <DownloadOutlined />
                                                                </IconButton>
                                                            </Box>
                                                            <Box sx={{ borderRadius: '6px', border: `1px solid ${timbalGrey[300]}`, height: '100%', overflowY: 'auto', p: 2 }}>
                                                                <ReactJson
                                                                    src={json}
                                                                    collapseStringsAfterLength={25}
                                                                    collapsed={2}
                                                                    displayDataTypes={false}
                                                                    enableClipboard={false}
                                                                    iconStyle='square'
                                                                    name={false}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Panel>
                                                </PanelGroup>
                                            </Box> : null}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            {nextPageToken && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <SecondaryButtonCanvas
                                        onClick={handleLoadMore}
                                        disabled={isLoadingMore}
                                    >
                                        {isLoadingMore ? 'Loading...' : 'Load More'}
                                    </SecondaryButtonCanvas>
                                </Box>
                            )}
                        </>
                }
            </Box>
        </Dialog>
    );
}

export default CanvaRunsModal;
