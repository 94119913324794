import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useWebSocket } from './WebSocketProvider';
import { useSnackbar } from 'notistack';
import { DEBUG } from 'utils/miscelanea';

const SaveFlowContext = createContext();

export const SaveFlowContextProvider = ({ children }) => {

    const { socketService, MessageCode, MessageType  } = useWebSocket();
    const [savingFlow, setSavingFlow] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const messagesQueue = useRef({});

    useEffect(() => {
        if (socketService) {
            // Add your message handlers
            socketService.addCallbacks({
                onSave: (data) => {
                    digestMessage(data);
                },
            });
        }
        // Cleanup when the component unmounts
        return () => {
            socketService.addCallbacks({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketService]);

    const digestMessage = (message) => {
        try {
            const { status, code } = JSON.parse(message);
            if (status === 200) {
                switch (code) {
                    case MessageCode.SAVED:
                        console.log('Flow saved successfully!');
                        enqueueSnackbar('Flow saved successfully!', { variant: 'success' });
                        setSavingFlow(false);
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error('Error parsing message:', error);
        }
    }

    const sendMessage = ({ action, parameters, client_message_id }) => {
        if (!socketService) return;
        if (DEBUG) {
            console.log('Sending message to server:', { action, parameters, client_message_id });
        }
        socketService.sendMessage(JSON.stringify({ action, parameters, client_message_id }));
        //websocket.current.send(JSON.stringify({ action, parameters, client_message_id }));
        if (client_message_id) {
            messagesQueue.current[client_message_id] = { req: { action, parameters, client_message_id } };
        }
    }

    //{"action": "save", "parameters": {}}

    const saveFlow = async ({ client_message_id } = {}) => {
        setSavingFlow(true);
        const uuid = Math.random().toString(36).substring(7);
        sendMessage({ action: MessageType.SAVE, parameters: {}, client_message_id: client_message_id || uuid });
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (messagesQueue.current[uuid] && messagesQueue.current[uuid].res) {
                    clearInterval(interval);
                    resolve(messagesQueue.current[uuid].res);
                    delete messagesQueue.current[uuid];
                }
            }, 100);
        });
    }


    return (
        <SaveFlowContext.Provider value={{ savingFlow, setSavingFlow, saveFlow }}>
            {children}
        </SaveFlowContext.Provider>
    );

}

export const useSaveFlow = () => {
    return useContext(SaveFlowContext);
}