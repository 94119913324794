import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const CustomImage = ({ src, alt, sx }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                aspectRatio: '1 / 1', // Black background for the square
                overflow: 'hidden', // Ensure the image doesn't overflow the container
                ...sx,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'black',
                }}
            >
                <CircularProgress sx={{ color: '#fff' }} size={30} thickness={3} />
            </Box>
            <img
                decoding='async'
                src={src}
                alt={alt}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                }}
            />
        </Box>
    );
};

export default CustomImage;
