import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { timbalGrey } from 'components/CustomColors';

const PopMenuButton = styled(Button)((_) => ({
    color: 'black',
    backgroundColor: 'transparent',
    borderRadius: '6px',
    textTransform: 'none',
    padding: '8px 16px',
    margin: '0px',
    '&:hover': {
        backgroundColor: '#E4EFF6',
    }
}));

const GreyButtonWithBorder = styled(Button)((_) => ({
    color: 'black',
    backgroundColor: 'transparent',
    borderRadius: '6px',
    textTransform: 'none',
    padding: '8px 16px',
    margin: '0px',
    border: `2px solid ${timbalGrey[300]}`,
    '&:hover': {
        backgroundColor: '#E4EFF6',
    }
}));

const MainButton = styled(Button)((_) => ({
    color: 'white',
    fontWeight: 700,
    backgroundColor: '#5012cb',
    borderRadius: '6px',
    padding: '5px 12px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#2f106c',
    },
    '&:disabled': {
        backgroundColor: timbalGrey[200],
        color: timbalGrey[700],
    }
}));

const NavBarButton = styled(Button)((_) => ({
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: '15px',
    textTransform: 'none',
    padding: '6px',
    margin: '0px',
    gap: '5px',
    '&:hover': {
        backgroundColor: '#E4EFF6',
    },
    //remove start icon margin
    '& .MuiButton-startIcon': {
        margin: 0,
    }
}));

const UpgradeButton = styled(Button)((_) => ({
    color: 'white',
    backgroundColor: 'black',
    borderRadius: '6px',
    textTransform: 'none',
    padding: '8px',
    margin: '0px',
    '&:hover': {
        backgroundColor: timbalGrey[700],
    }
}));

const ModalConfirmButton = styled(Button)((_) => ({
    color: 'white',
    backgroundColor: 'black',
    borderRadius: '6px',
    textTransform: 'none',
    padding: '8px ',
    margin: '0px',
    '&:hover': {
        backgroundColor: timbalGrey[700],
    },
    '&:disabled': {
        backgroundColor: timbalGrey[200],
        color: timbalGrey[700],
    }
}));

const ModalCancelButton = styled(Button)((_) => ({
    color: 'black',
    backgroundColor: timbalGrey[300],
    borderRadius: '6px',
    textTransform: 'none',
    padding: '8px ',
    margin: '0px',
    '&:hover': {
        backgroundColor: timbalGrey[700],
    }
}));

// Canvas

const MainButtonCanvas = styled(Button)((_) => ({
    color: 'white',
    fontWeight: 500,
    backgroundColor: '#5012cb',
    borderRadius: '6px',
    padding: '5px 12px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#2f106c',
    },
    '&:disabled': {
        backgroundColor: timbalGrey[200],
        color: timbalGrey[700],
    }
}));

const SecondaryButtonCanvas = styled(Button)((_) => ({
    color: 'black',
    backgroundColor: timbalGrey[300],
    borderRadius: '6px',
    textTransform: 'none',
    padding: '5px 12px',
    margin: '0px',
    '&:hover': {
        backgroundColor: timbalGrey[700],
    },
    '&:disabled': {
        backgroundColor: timbalGrey[200],
        color: timbalGrey[700],
    }
}));

const UpgradeBannerButton = styled(Button)((_) => ({
    color: 'white',
    backgroundColor: 'black',
    borderRadius: '100px',
    textTransform: 'none',
    padding: '10px 24px',
    margin: '0px',
    boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.4)',
    '&:hover': {
        backgroundColor: timbalGrey[800],
    }
}));

//Upgrade

const BuyPlanButton = styled(Button)((_) => ({
    color: 'white',
    fontWeight: 500,
    fontSize: '16px',
    backgroundColor: '#5012cb',
    borderRadius: '6px',
    padding: '8px 12px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#2f106c',
    },
    '&:disabled': {
        backgroundColor: 'black',
        color: 'white',
    }
}));


export {
    MainButton,
    GreyButtonWithBorder,
    NavBarButton,
    UpgradeButton,
    PopMenuButton,
    ModalCancelButton,
    ModalConfirmButton,
    MainButtonCanvas,
    SecondaryButtonCanvas,
    UpgradeBannerButton,
    BuyPlanButton
};