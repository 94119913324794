import * as React from 'react';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { timbalGrey } from 'components/CustomColors';
import { Select, MenuItem } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import InputHeader from './components/InputHeader';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '6px',
    backgroundColor: 'white',
    border: `1px solid ${timbalGrey[300]}`,
    padding: '10px 12px 10px 12px',
    '&:hover, &:focus': {
      border: '1px solid #5012cb',
    },

  },
  '& .MuiSelect-icon': {
    color: timbalGrey[700],
    fontSize: 20,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxWidth: 350,
      maxHeight: 300,
      backgroundColor: 'white',
      border: `1px solid ${timbalGrey[300]}`,
      boxShadow: 'none',
      focus: {
        backgroundColor: 'blue'
      }
    },
  },
};

// MenuItem component custom style

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  //backgroundColor: 'white !important',
  borderRadius: '6px',
  margin: '4px 8px',
  '&:hover, &:focus': {
    backgroundColor: '#E4EFF6 !important',
  },
  '&.Mui-selected': {
    backgroundColor: '#E4EFF6 !important',
    '&:hover': {
      backgroundColor: '#E4EFF6',
    }
  },
}));

export default function DropdownInput({ title, description, tip, onChange, onFocus, initialValue, choices, name, resetable = false, disabled }) {

  const [value, setValue] = React.useState(initialValue ?? null);

  const handleInputChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: 'column' }} gap={1}>
      <InputHeader title={title} description={description} boundaries={tip} name={name} />
      <Select
        name={name}
        size='small'
        value={value}
        disabled={disabled}
        onChange={handleInputChange}
        onFocus={onFocus}
        input={<BootstrapInput />}
        MenuProps={MenuProps}
      >
        {resetable && <MenuItemStyle value="">
          <Typography variant="body" noWrap>{"(empty)"}</Typography>
        </MenuItemStyle>}
        {choices.map((option, index) => (
          <MenuItemStyle key={index} value={typeof option === 'object' ? option.id : option}>
            <Typography variant="body" noWrap>{typeof option === 'object' ? option.name : option}</Typography>
          </MenuItemStyle>
        ))}
      </Select>
    </Box>
  );
}
