import React, { createContext, useContext, useEffect, useState } from 'react';
import { useWebSocket } from './WebSocketProvider';

const DataMissingContext = createContext();

export const DataMissingContextProvider = ({ children }) => {

    const { socketService, MessageCode } = useWebSocket();
    const [dataMissing, setDataMissing] = useState(null);

    useEffect(() => {
        if (socketService) {
            // Add your message handlers
            socketService.addCallbacks({
                onMessageDataMissing: (data) => {
                    digestMessage(data);
                },
            });
        }
        // Cleanup when the component unmounts
        return () => {
            socketService.addCallbacks({});
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketService]);

    const digestMessage = (message) => {
        try {
            const { status, body, code } = JSON.parse(message);
            if (status === 200) {
                switch (code) {
                    case MessageCode.SYNC:
                        setDataMissing((_) => { return body.data_missing });
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error('Error parsing message:', error);
        }
    }


    return (
        <DataMissingContext.Provider value={{ dataMissing }}>
            {children}
        </DataMissingContext.Provider>
    );

}

export const useDataMissing = () => {
    return useContext(DataMissingContext);
}