import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Dialog, Tabs, Tab, Avatar } from "@mui/material";
import { CancelOutlined, ExpandMore } from "@mui/icons-material";
import { MainButtonCanvas, ModalCancelButton, ModalConfirmButton } from "components/CustomButtons";
import { useWorkflow } from "canva/workflow/WorkflowContext";
import { capitalizeFirst } from "utils/miscelanea";
import InputsTooltip from "components/inputs/components/InputsTooltip";
import { AddOutlined } from "@mui/icons-material";
import TextInput from "components/inputs/TextInput";
import { styled } from '@mui/material/styles';
import { timbalGrey } from 'components/CustomColors';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails as MuiAccordionDetails } from '@mui/material';


const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '14px',
    color: timbalGrey[700],
    zIndex: 10,
    borderRadius: '6px',
    backgroundColor: 'transparent',
    '&.Mui-selected': {
        color: '#5012cb',
    },
    border: 'none',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: '#5012cb',
        height: 3,
        borderRadius: '6px',
        zIndex: 10,
    },
    marginBottom: theme.spacing(2),
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 1,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
}));

const ConnectableInputsModal = ({ open, onClose, inputTitle, stepId, stepParamName }) => {

    const { bodyCanva, getAvailableMappings, getAvailableInputsMappings, setMappingValue, setGlobalInput } = useWorkflow();
    const [availableMappings, setAvailableMappings] = useState(null);
    const [availableInputsMappings, setAvailableInputsMappings] = useState(null);
    const [tabValue, setTabValue] = useState(0);

    const [name, setName] = useState('');
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const [expandedAccordion, setExpandedAccordion] = useState(false);

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setName('');
    };

    useEffect(() => {
        if (open) {
            fetchAvailableMappings();
            fetchAvailableInputsMappings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, stepId, stepParamName]);

    const fetchAvailableMappings = async () => {
        const resp = await getAvailableMappings({ step_id: stepId, step_param_name: stepParamName });
        if (resp.status === 200 && resp.body.available_mappings) {
            resp.body.available_mappings.sort((a, b) => a.available ? -1 : 1);
            setAvailableMappings(resp.body.available_mappings);
        }
    };

    const fetchAvailableInputsMappings = async () => {
        const resp = await getAvailableInputsMappings({ step_id: stepId, step_param_name: stepParamName });
        if (resp.status === 200 && resp.body.available_inputs_mappings) {
            resp.body.available_inputs_mappings.sort((a, b) => a.available ? -1 : 1);
            setAvailableInputsMappings(resp.body.available_inputs_mappings);
        }
    };

    const handleConnect = (mapping) => {
        setMappingValue({
            step_id: stepId,
            step_param_name: stepParamName,
            ancestor_id: mapping.ancestor_id,
            ancestor_return_param_name: mapping.ancestor_return_param_name,
        });
        onClose();
    };

    const handleConnectGlobal = (inputName) => {
        if (inputName === 'new_global_input') {
            setOpenEditDialog(true);
            return;
        }
        setGlobalInput({
            step_id: stepId,
            step_param_name: stepParamName,
            input_name: inputName,
            client_message_id: 'set_inputs_outputs'
        });
        onClose();
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    const groupMappingsByStep = (mappings) => {
        return mappings.reduce((acc, mapping) => {
            if (!acc[mapping.ancestor_id]) {
                acc[mapping.ancestor_id] = [];
            }
            acc[mapping.ancestor_id].push(mapping);
            return acc;
        }, {});
    };

    return (<>
        <Dialog
            open={open}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={onClose}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>Connect '{inputTitle}'</Typography>
                <StyledTabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <StyledTab label="Steps" />
                    <StyledTab label="Global Inputs" />
                </StyledTabs>
                {tabValue === 0 && (
                    availableMappings && availableMappings.length > 0 ? (
                        Object.entries(groupMappingsByStep(availableMappings)).map(([ancestorId, mappings], index) => {
                            const stepName = bodyCanva.nodes.find(node => node.id === ancestorId).data.name;
                            return (
                                <Accordion
                                    key={ancestorId}
                                    expanded={expandedAccordion === `panel${index}`}
                                    onChange={handleAccordionChange(`panel${index}`)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                        sx={{
                                            boxShadow: 'none',
                                            border: `1px solid ${timbalGrey[300]}`,
                                            elevation: 0,
                                            p: 0,
                                            px: 1,
                                            borderRadius: '6px',
                                            '&:hover': {
                                                backgroundColor: timbalGrey[300],
                                            }
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 2 }}>
                                            <Avatar
                                                src={bodyCanva.nodes.find(step => step.id === ancestorId).data.icon_url}
                                                sx={{ width: 30, height: 30, borderRadius: '6px' }}
                                                variant="square"
                                            />
                                            <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                                {stepName}
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            {mappings.map((mapping, mappingIndex) => (
                                                <Box key={mappingIndex} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Typography variant="body2"><b>{capitalizeFirst(mapping.ancestor_return_param_name || 'result')}</b></Typography>
                                                    <Box sx={{ flexGrow: 1 }} />
                                                    <InputsTooltip title={mapping.message} placement="top">
                                                        <span>
                                                            <ModalConfirmButton onClick={() => handleConnect(mapping)} disabled={!mapping.available} >
                                                                Connect
                                                            </ModalConfirmButton>
                                                        </span>
                                                    </InputsTooltip>
                                                </Box>
                                            ))}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    ) : (
                        <Typography variant="body2">No available mappings from steps</Typography>
                    )
                )}

                {tabValue === 1 && (
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: `1px solid ${timbalGrey[300]}`, p: 2, mb: 2, borderRadius: '6px', backgroundColor: timbalGrey[200] }}>
                            <Typography variant="body2"><b>New Global Input</b></Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <MainButtonCanvas startIcon={<AddOutlined />} onClick={() => handleConnectGlobal('new_global_input')}>
                                Add
                            </MainButtonCanvas>
                        </Box>
                        {availableInputsMappings && availableInputsMappings.length > 0 ?
                            availableInputsMappings.map((mapping, index) => {
                                return (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography variant="body2"><b>{mapping.input_name}</b></Typography>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <InputsTooltip title={mapping.message} placement="top">
                                            <span>
                                                <ModalConfirmButton onClick={() => handleConnectGlobal(mapping.input_name)} disabled={!mapping.available} >
                                                    Connect
                                                </ModalConfirmButton>
                                            </span>
                                        </InputsTooltip>
                                    </Box>
                                )
                            }) :
                            <Typography variant="body2">No available global inputs</Typography>
                        }
                    </>
                )}
            </Box>
        </Dialog>
        {/* SETNAME DIALOG */}
        <Dialog
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseEditDialog}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>Name global input</Typography>
                <TextInput
                    label='Name global input'
                    pattern={"^[a-zA-Z_][a-zA-Z0-9_]{0,63}$"}
                    minLength={1}
                    maxLength={64}
                    initialValue={name}
                    placeholder={'Global input name'}
                    title={'Name'}
                    onChange={(e) => setName(e.target.value)}
                    multiline={false} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <ModalCancelButton onClick={handleCloseEditDialog} >
                            <Typography variant="body2">
                                Cancel
                            </Typography>
                        </ModalCancelButton>
                        <ModalConfirmButton onClick={() => handleConnectGlobal(name)} >
                            <Typography variant="body2" >
                                Save
                            </Typography>
                        </ModalConfirmButton>
                    </Box>
                </Box>
            </Box>
        </Dialog >
    </>
    );
};

export default ConnectableInputsModal;
