import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { timbalGrey } from 'components/CustomColors';

const CustomTextField = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: '6px',
        border: '1px solid',
        borderColor: timbalGrey[300],
        fontSize: 16,
        padding: '8px 10px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // bold placeholder text
        '&::placeholder': {
            fontWeight: 500,
            color: timbalGrey[700],
        },
    },
}));

export default CustomTextField;

