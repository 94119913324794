import { Dialog, Box, Typography, IconButton, CircularProgress, Tabs, Tab, Divider } from '@mui/material';
import { CancelOutlined, ExpandMore, BoltOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails as MuiAccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import { timbalGrey } from 'components/CustomColors';
import { prettyDate } from 'utils/miscelanea';
import { statusMap } from 'canva/utils/states';
import { MainButtonCanvas, SecondaryButtonCanvas } from 'components/CustomButtons';
import { useSnackbar } from 'notistack';
import { CopyAllOutlined } from '@mui/icons-material';
import { useApi } from 'api/ApiContext';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import * as rocket from 'lotties/rocket.json';
import { RefreshOutlined } from '@mui/icons-material';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';

SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('python', python);

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 1,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '14px',
    color: timbalGrey[700],
    zIndex: 10,
    borderRadius: '6px',
    backgroundColor: 'transparent',
    '&.Mui-selected': {
        color: '#5012cb',
    },
    border: 'none',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: '#5012cb',
        height: 3,
        borderRadius: '6px',
        zIndex: 10,
    },
    marginBottom: theme.spacing(2),
}));

const DeployModal = ({ openDeployDialog, setOpenDeployDialog, setOpenNewDeployDialog }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { getFlowsVersions } = useApi();

    const { id } = useParams();

    const [deployments, setDeployments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [expandedAccordion, setExpandedAccordion] = useState('panel0');
    const [activeTab, setActiveTab] = useState('getStarted');
    const [getStartedTabValue, setGetStartedTabValue] = useState(0);

    const handleCloseDeployDialog = () => {
        setOpenDeployDialog(false);
    }

    const handleOpenNewDeployDialog = () => {
        setOpenNewDeployDialog(true);
        setOpenDeployDialog(false);
    }

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setActiveTab('getStarted');
        setGetStartedTabValue(0);
        setExpandedAccordion(isExpanded ? panel : null);

    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleGetStartedTabChange = (event, newValue) => {
        setGetStartedTabValue(newValue);
    };

    useEffect(() => {
        if (openDeployDialog) {
            handleOpenDeployDialog();
        } else {
            setExpandedAccordion('panel0');
            setDeployments(null);
            setLoading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDeployDialog]);

    const handleOpenDeployDialog = async () => {
        setLoading(true);
        const resp = await getFlowsVersions(id);
        if (resp.success) {
            setDeployments(resp.success.flowVersions);
        }
        setLoading(false);
        setExpandedAccordion('panel0'); // Ensure the first accordion is open
    }

    const CodeBlockTimbal = ({ code, language }) => {
        const [copied, setCopied] = useState(false);

        const handleCopy = () => {
            navigator.clipboard.writeText(code);
            setCopied(true);
            enqueueSnackbar('Copied to clipboard', { variant: 'success' });
            setTimeout(() => setCopied(false), 2000);
        };

        return (
            <Box sx={{ position: 'relative' }}>
                <SyntaxHighlighter
                    language={language}
                    style={dracula}
                    wrapLines={true}
                    wrapLongLines={true}
                    customStyle={{
                        borderRadius: '6px',
                        border: `1px solid ${timbalGrey[300]}`,
                        fontFamily: 'monospace',
                        fontSize: 16,
                        padding: 20,
                        margin: 0,
                    }}
                >
                    {code}
                </SyntaxHighlighter>
                <SecondaryButtonCanvas
                    onClick={handleCopy}
                    startIcon={<CopyAllOutlined />}
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10
                    }}
                >
                    {copied ? 'Copied!' : 'Copy'}
                </SecondaryButtonCanvas>
            </Box>
        );
    }

    const renderSchemaBox = (title, schema) => (
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>{title}</Typography>
            {Object.entries(schema).length === 0 ? (
                <Typography variant="body2" sx={{ mt: 2 }}>No {title.toLowerCase()} available.</Typography>
            ) : (
                Object.entries(schema).map(([key, value]) => (
                    <Box key={key} sx={{ mt: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                            {key}
                            <span style={{ color: timbalGrey[700], fontFamily: 'monospace', fontSize: 12 }}>
                                {value.type}
                            </span>
                        </Typography>
                        {value.description && <Typography variant="body2">Description: {value.description}</Typography>}
                        {value.maxLength && <Typography variant="body2">Max Length: {value.maxLength}</Typography>}
                        {value.minLength && <Typography variant="body2">Min Length: {value.minLength}</Typography>}
                        {value.pattern && <Typography variant="body2">Pattern: {value.pattern}</Typography>}
                        {value.choices && <Typography variant="body2">Choices: {value.choices.join(', ')}</Typography>}
                        {value.default && <Typography variant="body2">Default: {value.default}</Typography>}
                        {value.minimum && <Typography variant="body2">Minimum: {value.minimum}</Typography>}
                        {value.maximum && <Typography variant="body2">Maximum: {value.maximum}</Typography>}
                        {value.step && <Typography variant="body2">Step: {value.step}</Typography>}
                    </Box>
                ))
            )}
        </Box >
    );

    const DeployingPlaceholder = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: rocket,
        };
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: 2, p: 2 }}>
                <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{"We're deploying your application... This may take a while."}</Typography>
                    <Typography variant="body2">{"You can close this window and check back later."}</Typography>
                </Box>
                <SecondaryButtonCanvas
                    startIcon={<RefreshOutlined />}
                    onClick={handleOpenDeployDialog}
                >
                    {"Refresh"}
                </SecondaryButtonCanvas>
            </Box>
        )
    }

    const DeployFailedPlaceholder = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: 2, p: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>{"Deployment failed."}</Typography>
                <Typography variant="body2">{"Please try again."}</Typography>
            </Box>
        )
    }

    const htmlCode = (id, inputsSchema) => {

        const inputsSchemaMap = Object.entries(inputsSchema.properties).map(([key, value]) => `"${key}": ""`).join(', ');

        return `curl -i "https://monolith.timbal.ai/runs" \\
-X POST \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer YOUR_API_TOKEN" \\
-d '{"versionId": "${id}", "inputs": {${inputsSchemaMap}}}'`
    }

    const pythonCode = (id, inputsSchema) => {
        const inputsSchemaMap = Object.entries(inputsSchema.properties).map(([key, value]) => `"${key}": ""`).join(', ');
        return `import requests

url = "https://monolith.timbal.ai/runs"

payload = {"versionId": "${id}", "inputs": {${inputsSchemaMap}}}
headers = {"Content-Type": "application/json", "Authorization": "Bearer YOUR_API_TOKEN"}

response = requests.post(url, json=payload, headers=headers)

if response.status_code == 200:
    print(response.json())
else:
    print(f"Error: {response.status_code}")
    print(response.text)`
    }

    const javascriptCode = (id, inputsSchema) => {
        const inputsSchemaMap = Object.entries(inputsSchema.properties).map(([key, value]) => `"${key}": ""`).join(', ');
        return `const runFlow = async () => {

    const response = await fetch("https://monolith.timbal.ai/runs", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer YOUR_API_TOKEN"
        },
        body: JSON.stringify({ "versionId": "${id}", "inputs": {${inputsSchemaMap}}})
    });

    if(response.ok){
        const data = await response.json();
        return data;
    } else {
        console.log(response);
    }
}

runFlow();`}

    return (
        <Dialog
            open={openDeployDialog}
            onClose={handleCloseDeployDialog}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
            maxWidth='lg'
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, pl: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>{"Deployments History"}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseDeployDialog}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 2, overflowY: 'auto', height: '90vh', maxHeight: '90vh' }}>
                {loading ?
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <CircularProgress size={30} color="inherit" />
                    </Box>
                    : deployments === null || deployments.length === 0 ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>{"No deployments yet. Deploy the application to see the history."}</Typography>
                            <MainButtonCanvas
                                startIcon={<BoltOutlined />}
                                onClick={handleOpenNewDeployDialog}
                            >
                                {"New deploy"}
                            </MainButtonCanvas>
                        </Box>
                        :
                        deployments.map((deployment, index) => (
                            <Accordion
                                key={index}
                                expanded={expandedAccordion === `panel${index}`}
                                onChange={handleAccordionChange(`panel${index}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                    sx={{
                                        boxShadow: 'none',
                                        border: `1px solid ${timbalGrey[300]}`,
                                        elevation: 0,
                                        p: 0,
                                        px: 1,
                                        borderRadius: '6px',
                                        '&:hover': {
                                            backgroundColor: timbalGrey[300],
                                        }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', width: '100%', pr: 2, pl: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 30, width: 30 }}>
                                                {statusMap[deployment.status.toLowerCase()].icon}
                                            </Box>
                                            <Typography sx={{ fontWeight: 700, minWidth: 250 }}>{`Version ${deployment.name} - ${prettyDate(deployment.createdAt)}`}</Typography>
                                        </Box>
                                        <Box flexGrow={1} />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {expandedAccordion === `panel${index}` && (
                                        deployment.status.toLowerCase() === 'building' ?
                                            <DeployingPlaceholder />
                                            : deployment.status.toLowerCase() === 'failed' ?
                                                <DeployFailedPlaceholder />
                                                :
                                                <Box sx={{ pt: 2, display: 'flex', gap: 2 }}>
                                                    <Box sx={{ minWidth: '150px', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                        <SecondaryButtonCanvas
                                                            onClick={() => handleTabChange('getStarted')}
                                                            sx={{
                                                                backgroundColor: activeTab === 'getStarted' ? timbalGrey[300] : 'transparent',
                                                                '&:hover': {
                                                                    backgroundColor: activeTab === 'getStarted' ? timbalGrey[400] : timbalGrey[200],
                                                                }
                                                            }}
                                                        >
                                                            Get Started
                                                        </SecondaryButtonCanvas>
                                                        <SecondaryButtonCanvas
                                                            onClick={() => handleTabChange('schema')}
                                                            sx={{
                                                                backgroundColor: activeTab === 'schema' ? timbalGrey[300] : 'transparent',
                                                                '&:hover': {
                                                                    backgroundColor: activeTab === 'schema' ? timbalGrey[400] : timbalGrey[200],
                                                                }
                                                            }}
                                                        >
                                                            Schema
                                                        </SecondaryButtonCanvas>
                                                    </Box>
                                                    <Box sx={{ flexGrow: 1, pl: 2 }}>
                                                        {activeTab === 'getStarted' ? (
                                                            <Box>
                                                                <StyledTabs
                                                                    value={getStartedTabValue}
                                                                    onChange={handleGetStartedTabChange}
                                                                >
                                                                    <StyledTab label="HTTP" />
                                                                    <StyledTab label="Python" />
                                                                    <StyledTab label="NodeJS" />
                                                                </StyledTabs>
                                                                <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                                    <Box>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                                            <Typography variant="body1" sx={{ fontWeight: 700 }}>{"1. Get your API Token"}</Typography>
                                                                            <Typography variant="body2">
                                                                                Copy your API Token or get a new one from the <a href="/api-tokens" target="_blank" rel="noopener noreferrer">API Tokens page</a>.
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                                            <Typography variant="body1" sx={{ fontWeight: 700 }}>{"2. Connect your AI application using the following code"}</Typography>
                                                                            <Typography variant="body2">
                                                                                Copy the code and run your AI application with the desired inputs. Use the your API Token for authentication.
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ overflow: 'auto', width: '100%' }}>
                                                                        {getStartedTabValue === 0 && (
                                                                            <CodeBlockTimbal code={htmlCode(deployment.id, deployment.inputsSchema)} language="bash" />
                                                                        )}
                                                                        {getStartedTabValue === 1 && (
                                                                            <CodeBlockTimbal code={pythonCode(deployment.id, deployment.inputsSchema)} language="python" />
                                                                        )}
                                                                        {getStartedTabValue === 2 && (
                                                                            <CodeBlockTimbal code={javascriptCode(deployment.id, deployment.inputsSchema)} language="jsx" />
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        ) : (
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                                {renderSchemaBox("Inputs", deployment.inputsSchema.properties)}
                                                                <Divider sx={{ mt: 2 }} />
                                                                {renderSchemaBox("Outputs", deployment.outputsSchema.properties)}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        ))
                }
            </Box>
        </Dialog>
    )


}

export default DeployModal;