import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { CancelOutlined, ExpandMore } from '@mui/icons-material';
import SelectableGlobalInput from './SelectableGlobalInput';
import { useWorkflow } from 'canva/workflow/WorkflowContext';
import { useEffect, useRef, useState } from 'react';
import { ModalCancelButton, ModalConfirmButton } from 'components/CustomButtons';
import TextInput from 'components/inputs/TextInput';
import { useSnackbar } from 'notistack';
import { useDataMissing } from 'canva/workflow/DataMissingContext';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails as MuiAccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import { timbalGrey } from 'components/CustomColors';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 1,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
}));


const GlobalInputsModal = ({ openInputsDialog, setOpenInputsDialog, sx }) => {

    const [selectableInputs, setSelectableInputs] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const { setGlobalInput, getAvailableInputs, bodyCanva } = useWorkflow();
    const { dataMissing } = useDataMissing();
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState('');
    const [selectedInputCounts, setSelectedInputCounts] = useState({});
    const [expandedAccordion, setExpandedAccordion] = useState(false);

    const selectedInputs = useRef([]);

    useEffect(() => {
        if (openInputsDialog) {
            handleOpenInputsDialog();
        }
        // eslint-disable-next-line
    }, [openInputsDialog]);

    const handleOpenInputsDialog = async () => {
        let allInputs = [];
        const response = await getAvailableInputs();
        response.body.available_inputs.map(node => {
            if (node.step_params_schema) {
                const { properties, required } = node.step_params_schema;
                if (properties) {
                    Object.entries(properties).forEach(([key, value]) => {
                        let sortKey = 0;
                        if (required.includes(key)) {
                            sortKey = 1;
                        }
                        if (dataMissing[node.step_id] && dataMissing[node.step_id].includes(key)) {
                            sortKey = 2;
                        }
                        if (selectedInputs.current.find(input => input.step_id === node.step_id && input.step_param_name === key)) {
                            value.checked = true;
                        }
                        allInputs.push({ step_id: node.step_id, step_param_name: key, ...value, sortKey });
                    });
                }
            }
            return null;
        });
        allInputs.sort((a, b) => b.sortKey - a.sortKey);
        setSelectableInputs(allInputs);
        setOpenInputsDialog(true);
    }

    const groupInputsByStep = (inputs) => {
        return inputs.reduce((acc, input) => {
            if (!acc[input.step_id]) {
                acc[input.step_id] = [];
            }
            acc[input.step_id].push(input);
            return acc;
        }, {});
    };

    const handleCloseInputsDialog = () => {
        selectedInputs.current = [];
        setOpenInputsDialog(false);
    }

    const handleOnChange = (e) => {
        const { input, value } = e.target;

        if (value) {
            selectedInputs.current.push(input);
        } else {
            selectedInputs.current = selectedInputs.current.filter(selectedInput => selectedInput.step_id !== input.step_id || selectedInput.step_param_name !== input.step_param_name);
        }

        // Update the count of selected inputs for each step
        const newCounts = selectedInputs.current.reduce((acc, input) => {
            acc[input.step_id] = (acc[input.step_id] || 0) + 1;
            return acc;
        }, {});
        setSelectedInputCounts(newCounts);

        if (selectedInputs.current.length > 0) {
            //all inputs must be the same type and format as the first in selectedInputs if not they must be disabled
            let firstInput = selectedInputs.current[0];
            let type = firstInput.type;
            let format = firstInput.format;
            let choices = firstInput.choices;
            let pattern = firstInput.pattern;
            setSelectableInputs(
                (prev) => prev.map(input => {
                    if (input.type === type && input.format === format && input.choices === choices && input.pattern === pattern) {
                        input.disabled = false;
                    } else {
                        input.disabled = true;
                    }
                    return input;
                }
                ));
        } else {
            // eslint-disable-next-line
            setSelectableInputs((prev) => prev.map(input => (input.disabled = false, input)));
        }
    }


    const handleOpenEditDialog = () => {
        if (selectedInputs.current.length > 0) {
            setOpenEditDialog(true);
        } else {
            enqueueSnackbar('Select at least one input', { variant: 'error' });
        }
    }

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    }

    const handleAddGlobalInput = () => {
        for (let i = 0; i < selectedInputs.current.length; i++) {
            setGlobalInput({
                step_id: selectedInputs.current[i].step_id,
                step_param_name: selectedInputs.current[i].step_param_name,
                input_name: name,
                client_message_id: i + 1 === selectedInputs.current.length ? 'set_inputs_outputs' : null
            });
        }
        handleCloseEditDialog();
        handleCloseInputsDialog();
    }

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    return (<>
        <Dialog
            open={openInputsDialog}
            onClose={handleCloseInputsDialog}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
        >

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseInputsDialog}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>{"Set global input"}</Typography>
                {selectableInputs.length > 0 ? (
                    Object.entries(groupInputsByStep(selectableInputs)).map(([stepId, inputs], index) => (
                        <Accordion
                            key={stepId}
                            expanded={expandedAccordion === `panel${index}`}
                            onChange={handleAccordionChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                sx={{
                                    boxShadow: 'none',
                                    border: `1px solid ${timbalGrey[300]}`,
                                    elevation: 0,
                                    p: 0,
                                    px: 1,
                                    borderRadius: '6px',
                                    '&:hover': {
                                        backgroundColor: timbalGrey[300],
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 2 }}>
                                    <Avatar
                                        src={bodyCanva.nodes.find(step => step.id === stepId).data.icon_url}
                                        sx={{ width: 30, height: 30, borderRadius: '6px' }}
                                        variant="square"
                                    />
                                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                        {bodyCanva.nodes.find(step => step.id === stepId).data.name}
                                    </Typography>
                                    {selectedInputCounts[stepId] > 0 && (
                                        <Typography variant="body2" sx={{ fontWeight: 500, color: '#5012cb' }}>
                                            ({selectedInputCounts[stepId]} selected)
                                        </Typography>
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    {inputs.map((input, inputIndex) => (
                                        <SelectableGlobalInput key={inputIndex} input={input} handleOnChange={handleOnChange} />
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : null}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <ModalCancelButton onClick={handleCloseInputsDialog} >
                            <Typography variant="body2">
                                Cancel
                            </Typography>
                        </ModalCancelButton>
                        <ModalConfirmButton onClick={handleOpenEditDialog}>
                            <Typography variant="body2" >
                                Set input
                            </Typography>
                        </ModalConfirmButton>
                    </Box>
                </Box>
            </Box>
        </Dialog >
        {/* SETNAME DIALOG */}
        <Dialog
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseEditDialog}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>Name global input</Typography>
                <TextInput
                    label='Name global input'
                    pattern={"^[a-zA-Z_][a-zA-Z0-9_]{0,63}$"}
                    minLength={1}
                    maxLength={64}
                    initialValue={name}
                    placeholder={'Global input name'}
                    title={'Name'}
                    onChange={(e) => setName(e.target.value)}
                    multiline={false} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <ModalCancelButton onClick={handleCloseEditDialog} >
                            <Typography variant="body2">
                                Cancel
                            </Typography>
                        </ModalCancelButton>
                        <ModalConfirmButton onClick={handleAddGlobalInput} >
                            <Typography variant="body2" >
                                Save
                            </Typography>
                        </ModalConfirmButton>
                    </Box>
                </Box>
            </Box>
        </Dialog >
    </>);
}

export default GlobalInputsModal;