import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import CustomTextField from './components/CustomTextField';
import { timbalGrey } from 'components/CustomColors';
import InputHeader from './components/InputHeader';

const TextInput = ({ title, description, placeholder, initialValue, maxLength, minLength, pattern, onChange, multiline = true, name, disabled, tipChild, errorCallback }) => {
    const [value, setValue] = useState(initialValue ?? '');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (errorCallback) {
            console.log('errorCallback', error);
            errorCallback(error);
        }
    // eslint-disable-next-line
    }, [error]);

    const boundaries = (() => {
        if (maxLength && minLength) {
            return `min: ${minLength}, max: ${maxLength}`;
        } else if (maxLength) {
            return `max: ${maxLength}`;
        } else if (minLength) {
            return `min: ${minLength}`;
        } else if (pattern) {
            return `pattern: ${pattern}`;
        } else {
            return null;
        }
    });

    const handleOnChange = (e) => {
        setError(false);
        const newValue = e.target.value;
        setValue(newValue);
        // Check if newValue meets maxLength requirement
        if (newValue.length > 0) {
            if (maxLength && newValue.length > maxLength) {
                setError(true);
                return;
            }
            // Check if newValue meets minLength requirement
            if (minLength && newValue.length < minLength) {
                setError(true);
                return;
            }
            // Check if newValue meets pattern requirement
            if (pattern && !new RegExp(pattern).test(newValue)) {
                setError(true);
                return;
            }
        }
        if (onChange) {
            onChange(e);
        }
    }

    return (
        <Box sx={{ display: "grid" }} gap={1}>
            <InputHeader title={title} description={description} boundaries={boundaries()} name={name} tipChild={tipChild} />
            <Box>
                <CustomTextField
                    placeholder={placeholder}
                    value={value ?? ''}
                    size="small"
                    fullWidth
                    name={name}
                    type={title === 'Password' ? 'password' : 'text'}
                    multiline={multiline}
                    onChange={handleOnChange}
                    disabled={disabled}
                    error={error} // Pass error state to input component
                    sx={{
                        '& .MuiInputBase-input': {
                            borderColor: error ? 'red' : timbalGrey[300],
                            '&:focus': {
                                borderColor: error ? 'red' : '#5012cb !important',
                            }, // Change border color if there's an error
                        },
                    }}
                />
                {error && (
                    <Box>
                        <Typography variant="caption" color="error" >{maxLength && value.length > maxLength && `Exceeds max length of ${maxLength} characters.`}  </Typography>
                        <Typography variant="caption" color="error" >{minLength && value.length < minLength && `Minimum length is ${minLength} characters.`} </Typography>
                        <Typography variant="caption" color="error" >{pattern && !new RegExp(pattern).test(value) && `Input does not match the required format.`} </Typography>
                    </Box>
                )}</Box>
        </Box>
    );
}

export default TextInput;
