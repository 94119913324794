import { timbalGrey } from 'components/CustomColors';
import React from 'react';
import { SmoothStepEdge } from 'reactflow';
import { useWorkflow } from '../WorkflowContext';

const CustomEdge = ({ id, sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, style = {}, selected }) => {

    const { bodyCanva } = useWorkflow();

    let animated = false;
    try {
        animated = bodyCanva.edges.find(edge => edge.id === id)?.animated ?? false;
    } catch (_) {}

    const edgeStyle = {
        stroke: selected ? '#5012cb' : animated ? '#00aaff' : timbalGrey[300],
        strokeWidth: 2,
        strokeDasharray: animated ? '5,5' : 'none', // Create a dashed line if animated
        animation: animated ? 'dash-animation 1s linear infinite' : 'none', // Apply animation if animated
        ...style
    };

    return (
        <SmoothStepEdge
            sourceX={sourceX}
            sourceY={sourceY}
            targetX={targetX}
            targetY={targetY}
            sourcePosition={sourcePosition}
            targetPosition={targetPosition}
            style={edgeStyle} />
    );
};

export default CustomEdge;
