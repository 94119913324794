import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Box, Typography, IconButton, Avatar } from '@mui/material';
import { CancelOutlined, ExpandMore } from '@mui/icons-material';
import { useWorkflow } from 'canva/workflow/WorkflowContext';
import { capitalizeFirst } from 'utils/miscelanea';
import { ModalConfirmButton, ModalCancelButton } from 'components/CustomButtons';
import TextInput from 'components/inputs/TextInput';
import { styled } from '@mui/material/styles';
import { timbalGrey } from 'components/CustomColors';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails as MuiAccordionDetails } from '@mui/material';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 1,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
}));

const GlobalOutputsModal = ({ openOutputsDialog, setOpenOutputsDialog }) => {

    const [allOutputs, setAllOutputs] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [name, setName] = useState('');

    const selectedOutput = useRef(null);

    const { getAvailableOutputs, setGlobalOutput, globalOutputs, bodyCanva } = useWorkflow();

    const [expandedAccordion, setExpandedAccordion] = useState(false);

    useEffect(() => {
        if (openOutputsDialog) {
            handleOpenOutputsDialog();
        }
        // eslint-disable-next-line
    }, [openOutputsDialog]);

    const handleCloseOutputsDialog = () => {
        setOpenOutputsDialog(false);
    }

    const handleOpenEditDialog = (output) => {
        selectedOutput.current = output;
        setOpenEditDialog(true);
    }

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    }

    const handleAddGlobalOutput = () => {
        setGlobalOutput({
            step_id: selectedOutput.current.step_id,
            output_name: name,
            step_return_param_name: selectedOutput.current.step_param_name === 'result' ? null : selectedOutput.current.step_param_name,
            client_message_id: 'set_inputs_outputs'
        });
        handleCloseEditDialog();
        handleCloseOutputsDialog();
    }

    const handleOpenOutputsDialog = async () => {
        let allOutputs = [];
        const response = await getAvailableOutputs();
        response.body.available_outputs.map(node => {
            const step_name = bodyCanva.nodes.find(n => n.id === node.step_id).data.name;
            if (node.step_return_schema) {
                const { properties } = node.step_return_schema;
                let already_set_as = null;
                if (properties) {
                    Object.entries(properties).forEach(([key, value]) => {
                        if (globalOutputs.mappings.find(output => output.step_id === node.step_id && output.step_return_param_name === key)) {
                            already_set_as = globalOutputs.mappings.find(output => output.step_id === node.step_id && output.step_return_param_name === key).output_name;
                        }
                        allOutputs.push({ step_id: node.step_id, step_name, step_param_name: key, ...value, already_set_as });
                    });
                } else {
                    if (globalOutputs.mappings.find(output => output.step_id === node.step_id)) {
                        already_set_as = globalOutputs.mappings.find(output => output.step_id === node.step_id).output_name;
                    }
                    allOutputs.push({ step_id: node.step_id, step_name, step_param_name: 'result', ...node.step_return_schema, already_set_as });
                }
            }
            return null;
        });

        allOutputs.sort((a, b) => {
            if (a.already_set_as === null) return -1;
            if (b.already_set_as === null) return 1;
            return 0;
        });
        setAllOutputs(allOutputs);
    }

    const groupOutputsByStep = (outputs) => {
        return outputs.reduce((acc, output) => {
            if (!acc[output.step_id]) {
                acc[output.step_id] = [];
            }
            acc[output.step_id].push(output);
            return acc;
        }, {});
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    return (<>
        <Dialog
            open={openOutputsDialog}
            onClose={handleCloseOutputsDialog}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseOutputsDialog}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>{"Set global output"}</Typography>
                {allOutputs.length > 0 ? (
                    Object.entries(groupOutputsByStep(allOutputs)).map(([stepId, outputs], index) => (
                        <Accordion
                            key={stepId}
                            expanded={expandedAccordion === `panel${index}`}
                            onChange={handleAccordionChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                sx={{
                                    boxShadow: 'none',
                                    border: `1px solid ${timbalGrey[300]}`,
                                    elevation: 0,
                                    p: 0,
                                    px: 1,
                                    borderRadius: '6px',
                                    '&:hover': {
                                        backgroundColor: timbalGrey[300],
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 2 }}>
                                    <Avatar
                                        src={bodyCanva.nodes.find(step => step.id === stepId).data.icon_url}
                                        sx={{ width: 30, height: 30, borderRadius: '6px' }}
                                        variant="square"
                                    />
                                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                        {outputs[0].step_name}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    {outputs.map((output, outputIndex) => (
                                        <Box key={outputIndex} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Typography variant="body2"><b>{capitalizeFirst(output.step_param_name || 'result')}</b></Typography>
                                            <Box sx={{ flexGrow: 1 }} />
                                            <ModalConfirmButton onClick={() => handleOpenEditDialog(output)} disabled={output.already_set_as} >
                                                {output.already_set_as ? `Set as '${output.already_set_as}' ` : 'Connect'}
                                            </ModalConfirmButton>
                                        </Box>
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : null}
            </Box>
        </Dialog >
        {/* SETNAME DIALOG */}
        < Dialog
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleCloseEditDialog}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4, pt: 0, }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>Name global output</Typography>
                <TextInput
                    label='Name global output'
                    pattern={"^[a-zA-Z_][a-zA-Z0-9_]{0,63}$"}
                    minLength={1}
                    maxLength={64}
                    initialValue={name}
                    placeholder={'Global output name'}
                    title={'Name'}
                    onChange={(e) => setName(e.target.value)}
                    multiline={false} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <ModalCancelButton onClick={handleCloseEditDialog} >
                            <Typography variant="body2">
                                Cancel
                            </Typography>
                        </ModalCancelButton>
                        <ModalConfirmButton onClick={handleAddGlobalOutput} >
                            <Typography variant="body2" >
                                Save
                            </Typography>
                        </ModalConfirmButton>
                    </Box>
                </Box>
            </Box>
        </Dialog >
    </>);
};

export default GlobalOutputsModal;