import React, { createContext, useContext } from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useWorkflow } from './WorkflowContext';

const RightDrawerContext = createContext();


let minDrawerWidth = 400;
let maxDrawerWidth = 1200;

export const RightDrawerContextProvider = ({ children }) => {

    const [drawerWidth, setDrawerWidth] = useState(minDrawerWidth);
    const { sendSync } = useWorkflow();

    useEffect(() => {
        if ((document.body.offsetWidth * 0.25) > minDrawerWidth) {
            // Set the drawer width to 0.25 of the screen width
            setDrawerWidth(document.body.offsetWidth * 0.25);
        }
    }, []);

    const handleMouseDown = e => {
        sendSync();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        const newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);

    return (
        <RightDrawerContext.Provider value={{ drawerWidth, handleMouseDown }}>
            {children}
        </RightDrawerContext.Provider>
    );

}

export const useRightDrawer = () => {
    return useContext(RightDrawerContext);
}